'use client';

import { cn } from '@/lib/utils';
import React from 'react';
const Progressing = ({
  description,
  className
}: {
  description?: React.ReactNode;
  className?: string;
}) => {
  return <div className="h-[158px] w-[247px] flex flex-col items-center justify-center gap-8 mx-auto my-10 lg:my-20" data-sentry-component="Progressing" data-sentry-source-file="Progressing.tsx">
      <div role="status">
        <svg width="97" height="96" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg" className={cn('animate-spin', className)} data-sentry-element="svg" data-sentry-source-file="Progressing.tsx">
          <path d="M48.4998 4.80078C54.1729 4.80078 59.7905 5.91818 65.0317 8.08919C70.273 10.2602 75.0353 13.4423 79.0468 17.4538C83.0583 21.4653 86.2404 26.2276 88.4114 31.4689C90.5824 36.7101 91.6998 42.3277 91.6998 48.0008C91.6998 53.6739 90.5824 59.2914 88.4114 64.5327C86.2404 69.774 83.0583 74.5363 79.0468 78.5478C75.0353 82.5593 70.273 85.7414 65.0317 87.9124C59.7905 90.0834 54.1729 91.2008 48.4998 91.2008C42.8267 91.2008 37.2091 90.0834 31.9679 87.9124C26.7266 85.7414 21.9643 82.5593 17.9528 78.5478C13.9413 74.5363 10.7592 69.774 8.5882 64.5327C6.4172 59.2914 5.2998 53.6739 5.2998 48.0008C5.29981 42.3277 6.41721 36.7101 8.58822 31.4688C10.7592 26.2276 13.9413 21.4652 17.9528 17.4538C21.9643 13.4423 26.7266 10.2602 31.9679 8.08918C37.2092 5.91817 42.8267 4.80078 48.4998 4.80078L48.4998 4.80078Z" stroke="#F0EEF0" strokeWidth="9.6" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Progressing.tsx" />
          <path d="M48.4998 4.80078C57.6228 4.80078 66.5115 7.68892 73.8921 13.0512C81.2727 18.4136 86.7663 25.9748 89.5854 34.6512C92.4046 43.3277 92.4046 52.6739 89.5854 61.3503C86.7663 70.0268 81.2727 77.588 73.8921 82.9503" stroke="#892DCC" strokeWidth="9.6" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Progressing.tsx" />
        </svg>

        <span className="sr-only">Processing...</span>
      </div>

      <div className="text-lg text-black-brand leading-[30px]">{description}</div>
    </div>;
};
export default Progressing;