import { approveOrDeclineCommunity } from '@/services/communities.service';
import { ApproveOrDeclinePayloadType } from '@/types';
import { useMutation } from '@tanstack/react-query';

const useApproveOrRejectCommunityMutation = () => {
  return useMutation({
    mutationKey: ['ApproveOrRejectCommunity'],
    mutationFn: (payload: ApproveOrDeclinePayloadType) => approveOrDeclineCommunity(payload),
  });
};

export default useApproveOrRejectCommunityMutation;
