import React from 'react';
import CommunityItem from './CommunityItem';
import { CommunitySummaryType } from '@/types';
import { routePaths } from '@/routes/routePaths';
import { usePathname } from 'next/navigation';
interface CommunityListProps {
  title?: string;
  communities: CommunitySummaryType[];
  count?: number;
}
const CommunityList: React.FC<CommunityListProps> = ({
  title,
  communities,
  count = 3
}) => {
  const pathname = usePathname();
  const sterilizedTitle = title === 'Others' && pathname === '/' ? 'Communities' : title;
  return <div className="p-4" data-sentry-component="CommunityList" data-sentry-source-file="CommunityList.tsx">
      {title && <div className="flex items-center justify-between mb-2">
          <h3 className="text-xs leading-[18px] uppercase text-gray-brand">{sterilizedTitle}</h3>
          <a href={routePaths.explore.publicCommunities} className="text-orange-brand text-sm font-medium">
            See More
          </a>
        </div>}
      {communities.slice(0, count).map((community, index) => <CommunityItem isOwner={title?.toLowerCase() === 'owned'} isJoined={title?.toLowerCase() === 'joined'} slug={community.slug} key={index} imageUrl={community.imageUrl} title={community.name} membersCount={community.memberCount} />)}
    </div>;
};
export default CommunityList;