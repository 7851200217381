import { cn } from '@/lib/utils';
import Image from 'next/image';
import React from 'react';
type Props = {
  title: string;
  imgUrl?: string;
  action?: React.ReactNode;
  filled?: boolean;
  description?: string;
};
export default function EmptyContent({
  title,
  imgUrl,
  action,
  filled,
  description
}: Props) {
  return <div className={cn('flex-grow flex-1 flex-col items-center justify-center flex px-3 h-full', filled && 'rounded-lg bg-gray-brand/40 border-gray-brand/80')} data-sentry-component="EmptyContent" data-sentry-source-file="empty-content.tsx">
      <Image height={160} width={160} alt="empty content" src={imgUrl || '/assets/icons/empty/ic_content.svg'} crossOrigin="anonymous" priority data-sentry-element="Image" data-sentry-source-file="empty-content.tsx" />

      {title && <h6 className="text-center text-gray-brand2 text-sm">{title}</h6>}

      {description && <p className="text-center text-gray-brand3 text-xs mb-4">{description}</p>}

      {action && action}
    </div>;
}