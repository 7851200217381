import React from 'react';
import { Skeleton } from '../atom/skeleton';
const EditEventDetailLoading = () => {
  return <div className="w-full flex flex-col gap-6" data-sentry-component="EditEventDetailLoading" data-sentry-source-file="EditEventDetailLoading.tsx">
      <div className="flex flex-col">
        <div className="flex items-center justify-between gap-1">
          <Skeleton className="w-[100px] h-4" data-sentry-element="Skeleton" data-sentry-source-file="EditEventDetailLoading.tsx" />
          <Skeleton className="w-[82px] h-4" data-sentry-element="Skeleton" data-sentry-source-file="EditEventDetailLoading.tsx" />
        </div>
        <Skeleton className="w-full h-9" data-sentry-element="Skeleton" data-sentry-source-file="EditEventDetailLoading.tsx" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex flex-col gap-1">
          <Skeleton className="w-[55px] h-4" data-sentry-element="Skeleton" data-sentry-source-file="EditEventDetailLoading.tsx" />
          <Skeleton className="w-full h-9" data-sentry-element="Skeleton" data-sentry-source-file="EditEventDetailLoading.tsx" />
        </div>
        <div className="flex flex-col gap-1">
          <Skeleton className="w-[36px] h-4" data-sentry-element="Skeleton" data-sentry-source-file="EditEventDetailLoading.tsx" />
          <Skeleton className="w-full h-9" data-sentry-element="Skeleton" data-sentry-source-file="EditEventDetailLoading.tsx" />
        </div>
      </div>

      <div className="flex gap-4">
        <Skeleton className="w-[36px] h-5 rounded-3xl" data-sentry-element="Skeleton" data-sentry-source-file="EditEventDetailLoading.tsx" />
        <Skeleton className="w-[76px] h-4" data-sentry-element="Skeleton" data-sentry-source-file="EditEventDetailLoading.tsx" />
      </div>

      <div className="flex flex-col gap-1">
        <Skeleton className="w-[32px] h-4" data-sentry-element="Skeleton" data-sentry-source-file="EditEventDetailLoading.tsx" />
        <div className="flex items-center gap-4">
          <Skeleton className="w-2/4 h-9" data-sentry-element="Skeleton" data-sentry-source-file="EditEventDetailLoading.tsx" />
          <Skeleton className="w-1/4 h-9" data-sentry-element="Skeleton" data-sentry-source-file="EditEventDetailLoading.tsx" />
          <Skeleton className="w-1/4 h-9" data-sentry-element="Skeleton" data-sentry-source-file="EditEventDetailLoading.tsx" />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col gap-1">
          <Skeleton className="w-[34px] h-4" data-sentry-element="Skeleton" data-sentry-source-file="EditEventDetailLoading.tsx" />
          <Skeleton className="w-full h-9" data-sentry-element="Skeleton" data-sentry-source-file="EditEventDetailLoading.tsx" />
        </div>
        <div className="flex flex-col gap-1">
          <Skeleton className="w-[58px] h-4" data-sentry-element="Skeleton" data-sentry-source-file="EditEventDetailLoading.tsx" />
          <Skeleton className="w-full h-9" data-sentry-element="Skeleton" data-sentry-source-file="EditEventDetailLoading.tsx" />
        </div>
      </div>
    </div>;
};
export default EditEventDetailLoading;