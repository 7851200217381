'use client';

import React from 'react';
import { Button } from '@/components/atom/button';
import { usePathname, useRouter } from 'next/navigation';
import { Plus } from 'lucide-react';
import { cn } from '@/lib/utils';
const CreateButton = ({
  isAuthenticated,
  className
}: {
  isAuthenticated: boolean;
  className?: string;
}) => {
  const router = useRouter();
  const pathname = usePathname();
  return <Button className={cn('w-full', className)} onClick={() => {
    if (isAuthenticated) {
      router.push(`${pathname}?ref=create`);
      // TODO: Show Create Post/Community Modal
    } else {
      router.push(`${pathname}?ref=signin`);
    }
  }} data-sentry-element="Button" data-sentry-component="CreateButton" data-sentry-source-file="CreateButton.tsx">
      <Plus className="mr-0 md:mr-2" data-sentry-element="Plus" data-sentry-source-file="CreateButton.tsx" /> <span className="hidden md:inline-block">Create</span>
    </Button>;
};
export default CreateButton;