'use client';

import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/atom/dialog';
import React from 'react';
import SignInForm from './SignInForm';
import { useRouter, useSearchParams, usePathname } from 'next/navigation';
const SignInModal = () => {
  const router = useRouter();
  const params = useSearchParams();
  const pathname = usePathname();
  return <Dialog open={params.get('ref') === 'signin'} onOpenChange={() => router.replace(pathname)} data-sentry-element="Dialog" data-sentry-component="SignInModal" data-sentry-source-file="SigninModal.tsx">
      <DialogContent className="w-screen h-screen md:h-fit rounded-none md:rounded-lg md:w-full md:max-w-[480px] p-4 md:p-10 antialiased flex flex-col" data-sentry-element="DialogContent" data-sentry-source-file="SigninModal.tsx">
        <DialogHeader className="w-full max-w-[320px] mx-auto mt-10 text-start" data-sentry-element="DialogHeader" data-sentry-source-file="SigninModal.tsx">
          <DialogTitle className="relative font-medium text-2xl text-purple-brand3" data-sentry-element="DialogTitle" data-sentry-source-file="SigninModal.tsx">
            <span className="sr-only">Sign in Modal</span>
          </DialogTitle>
        </DialogHeader>
        <SignInForm className="shadow-none" onSigninComplete={() => router.replace(pathname)} data-sentry-element="SignInForm" data-sentry-source-file="SigninModal.tsx" />
      </DialogContent>
    </Dialog>;
};
export default SignInModal;