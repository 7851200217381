import { axiosClientInstance } from '@/configs/axiosClientInstance';
import { FUSION_API } from '@/configs/global-variables';
import { NOTIFICATIONS_ENDPOINTS } from '@/constants/apiPath';
import {
  ToggleReadStatusNotificationPayloadType,
  DeleteNotificationPayloadType,
  MarkAllNotificationsAsReadPayloadType,
} from '@/types';
import handleError, { ErrorPayload } from '@/utils/handleError';
import { AxiosError } from 'axios';

export async function toggleReadStatusNotification(
  params: ToggleReadStatusNotificationPayloadType
) {
  try {
    const res = await axiosClientInstance.patch(
      `${NOTIFICATIONS_ENDPOINTS.toggleReadStatus}/${params.id}/${params.readStatus}`,
      {},
      {
        baseURL: FUSION_API.apiMicroServiceNotifications,
      }
    );
    return res.data?.data;
  } catch (error) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}

export async function deleteNotification(params: DeleteNotificationPayloadType) {
  try {
    const res = await axiosClientInstance.delete(`${NOTIFICATIONS_ENDPOINTS.deleteNotification}`, {
      params,
      baseURL: FUSION_API.apiMicroServiceNotifications,
    });
    return res.data?.data;
  } catch (error) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}

export const markAllAsReadMutation = async function (
  params: MarkAllNotificationsAsReadPayloadType
) {
  try {
    const res = await axiosClientInstance.put(
      `${NOTIFICATIONS_ENDPOINTS.markAllAsRead}/${params.UserId}/notifications/mark-all-read`,
      { userId: params.UserId },
      {
        baseURL: FUSION_API.apiMicroServiceNotifications,
      }
    );
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
};
