import { Button } from "@/components/atom/button";
import { Input } from "@/components/atom/input";
import { EmailModel } from "@/lib/models";
import { Loader2Icon } from "lucide-react";
import React from "react";
import { UseFormReturn } from "react-hook-form";
type Props = {
  form: UseFormReturn<EmailModel, any, undefined>;
  onSubmit: (v: EmailModel) => void;
  loading: boolean;
  onCancel: () => void;
};
const StartPasswordResetForm = ({
  form,
  onSubmit,
  loading,
  onCancel
}: Props) => {
  return <form onSubmit={form.handleSubmit(onSubmit)} className="w-full max-w-[320px] flex-1  h-full flex-grow mx-auto flex items-center justify-between flex-col mb-10 gap-10" data-sentry-component="StartPasswordResetForm" data-sentry-source-file="StartPasswordResetForm.tsx">
      <div className="w-full">
        <label htmlFor="email">Email address</label>
        <Input {...form.register("email")} type="text" placeholder="Enter your email address" errorMessage={form.formState.errors.email?.message} disabled={loading} data-sentry-element="Input" data-sentry-source-file="StartPasswordResetForm.tsx" />
      </div>

      <div className="flex items-center justify-center w-full gap-4">
        <Button type="button" variant="outline" onClick={onCancel} data-sentry-element="Button" data-sentry-source-file="StartPasswordResetForm.tsx">
          Cancel
        </Button>
        <Button type="submit" className="w-full" disabled={!form.formState.isValid || loading} data-sentry-element="Button" data-sentry-source-file="StartPasswordResetForm.tsx">
          {loading ? <Loader2Icon size={20} className="animate-spin" /> : "Next"}
        </Button>
      </div>
    </form>;
};
export default StartPasswordResetForm;