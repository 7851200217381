'use client';

import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/atom/dialog';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import React from 'react';
import ActionButton from './ActionButton';
import { BulkShoppingCart, EditCommunityIcon, EditIcon } from '@/components/icons';
import { MODAL_PARAMS } from '@/constants';
const PreCreateModal = () => {
  const router = useRouter();
  const params = useSearchParams();
  const pathname = usePathname();
  return <Dialog open={params.get('ref') === 'create' && !params.get('type')} onOpenChange={() => router.push(pathname)} data-sentry-element="Dialog" data-sentry-component="PreCreateModal" data-sentry-source-file="PreCreateModal.tsx">
      <DialogContent className="w-[80vw] h-[90vh] md:h-auto p-4 md:p-10 antialiased flex flex-col items-center bg-pink-brand md:w-full max-w-[668px] rounded-xl" data-sentry-element="DialogContent" data-sentry-source-file="PreCreateModal.tsx">
        <DialogHeader className="w-full max-w-[320px] mx-auto mt-10 text-start" data-sentry-element="DialogHeader" data-sentry-source-file="PreCreateModal.tsx">
          <DialogTitle className="relative font-medium text-2xl text-purple-brand3" data-sentry-element="DialogTitle" data-sentry-source-file="PreCreateModal.tsx">
            <span className="sr-only">What would you like to create today?</span>
            <p className="text-black-brand text-lg text-center md:text-start">
              What would you like to create today?
            </p>
          </DialogTitle>
        </DialogHeader>
        <div className="flex items-center justify-center flex-wrap gap-8 w-full mb-10 mt-6">
          <ActionButton onClick={() => router.push(`${pathname}?${MODAL_PARAMS.type}=${MODAL_PARAMS.newPost}`)} icon={<EditIcon />} data-sentry-element="ActionButton" data-sentry-source-file="PreCreateModal.tsx">
            Post
          </ActionButton>
          <ActionButton onClick={() => router.push(`${pathname}?ref=create&type=community`)} icon={<EditCommunityIcon />} data-sentry-element="ActionButton" data-sentry-source-file="PreCreateModal.tsx">
            Community
          </ActionButton>
          <ActionButton onClick={() => router.push(`${pathname}?ref=create&type=store-item`)} icon={<BulkShoppingCart />} data-sentry-element="ActionButton" data-sentry-source-file="PreCreateModal.tsx">
            Item for Sale
          </ActionButton>
        </div>
      </DialogContent>
    </Dialog>;
};
export default PreCreateModal;