// app/error.tsx
'use client';

import { Button } from '@/components/atom/button';
import { useMixpanel } from '@/providers/MixPanelProvider';
export default function Error({
  error,
  reset
}: {
  error: Error;
  reset: () => void;
}) {
  const {
    trackEvent
  } = useMixpanel();
  return <div data-sentry-component="Error" data-sentry-source-file="error.tsx">
      <h2>Something went wrong!</h2>
      <p>{error.message}</p>
      <Button onClick={() => {
      reset();
      trackEvent('button_click', {
        name: 'Try again to fix error'
      });
    }} data-sentry-element="Button" data-sentry-source-file="error.tsx">
        Try again
      </Button>
    </div>;
}