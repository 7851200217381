import { Button } from '@/components/atom/button';
import { PasswordInput } from '@/components/atom/password-input';
import { ChangePasswordModel } from '@/lib/models';
import { Loader2Icon } from 'lucide-react';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
type Props = {
  form: UseFormReturn<ChangePasswordModel, any, undefined>;
  onSubmit: (v: ChangePasswordModel) => void;
  loading: boolean;
  onCancel: () => void;
};
const NewPasswordForm = ({
  form,
  onSubmit,
  loading,
  onCancel
}: Props) => {
  return <form onSubmit={form.handleSubmit(onSubmit)} className="w-full max-w-[320px] flex-1  h-full flex-grow mx-auto flex items-center justify-between flex-col mb-10 gap-10" data-sentry-component="NewPasswordForm" data-sentry-source-file="NewPasswordForm.tsx">
      <div className="space-y-6 w-full">
        <div className="w-full">
          <label htmlFor="password">Password</label>

          <PasswordInput {...form.register('password')} type="text" placeholder="Enter your password" errorMessage={form.formState.errors.password?.message} disabled={loading} hideInnerChecks data-sentry-element="PasswordInput" data-sentry-source-file="NewPasswordForm.tsx" />
        </div>
        <div className="w-full">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <PasswordInput {...form.register('confirmPassword')} type="text" placeholder="Confirm your password" errorMessage={form.formState.errors.confirmPassword?.message} disabled={loading} hideInnerChecks data-sentry-element="PasswordInput" data-sentry-source-file="NewPasswordForm.tsx" />
        </div>
      </div>

      <div className="flex items-center justify-center w-full gap-4">
        <Button type="button" variant="outline" onClick={onCancel} data-sentry-element="Button" data-sentry-source-file="NewPasswordForm.tsx">
          Cancel
        </Button>
        <Button type="submit" className="w-full" disabled={!form.formState.isValid || loading} data-sentry-element="Button" data-sentry-source-file="NewPasswordForm.tsx">
          {loading ? <Loader2Icon size={20} className="animate-spin" /> : 'Save'}
        </Button>
      </div>
    </form>;
};
export default NewPasswordForm;