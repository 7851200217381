'use client';

import { cn } from '@/lib/utils';
import React, { useState, useEffect } from 'react';
import { getRandomText } from '@/utils/generate-random-text';
import Image from 'next/image';
const Avatar = ({
  src = '',
  fallbackText,
  isOnline,
  className,
  initialsClassName,
  statusClassName,
  hideStatus
}: {
  src?: string;
  fallbackText: string;
  isOnline?: boolean;
  className?: string;
  statusClassName?: string;
  initialsClassName?: string;
  hideStatus?: boolean;
}) => {
  const initials = fallbackText?.split(' ').map(name => name[0]).join('') ?? getRandomText(1);
  const [isValidImage, setIsValidImage] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Function to validate if the URL points to an image
    const validateImageUrl = async (url: string) => {
      try {
        const res = await fetch(url);
        const contentType = res.headers.get('content-type');
        if (res.ok && contentType && contentType.startsWith('image/')) {
          setIsValidImage(true);
          setLoading(false);
        } else {
          setIsValidImage(false);
          setLoading(false);
        }
      } catch (error) {
        setIsValidImage(false);
        setLoading(false);
      }
    };
    validateImageUrl(src);
  }, [src]);
  return <div className={cn('relative w-16 h-16 rounded-full shadow-crd border-2 border-white', className)} data-sentry-component="Avatar" data-sentry-source-file="avatar.tsx">
      {loading ? <div className="h-full rounded-full w-full bg-slate-300" /> : isValidImage ? <Image src={src} alt="avatar" width={400} height={400} className="w-full h-full rounded-full object-cover" crossOrigin="anonymous" priority /> : <div className="w-full h-full flex items-center justify-center bg-pink-brand rounded-full">
          <span className={cn('text-sm font-semibold text-purple-brand4 uppercase', initialsClassName)}>
            {initials?.slice(0, 2)}
          </span>
        </div>}

      {isOnline && <span className={cn('absolute -bottom-1 right-1 w-3 h-3 rounded-full border-2 border-white bg-success', statusClassName, hideStatus && 'hidden')} />}
    </div>;
};
export default Avatar;