'use client';

import * as React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import useModalControl from '@/hooks/store/use-modal-control';
import { Button } from './atom/button';
import { X } from 'lucide-react';
import { useDeviceType } from '@/hooks/use-device-type';
import Link from 'next/link';
const JoinPrivateCommunity = () => {
  const {
    modalType,
    closeModal
  } = useModalControl();
  const deviceType = useDeviceType();
  return <Dialog.Root open={modalType.type === 'joinPrivateCommunity' && modalType.isOpen} onOpenChange={closeModal} data-sentry-element="unknown" data-sentry-component="JoinPrivateCommunity" data-sentry-source-file="JoinPrivateCommunity.tsx">
      <Dialog.Portal data-sentry-element="unknown" data-sentry-source-file="JoinPrivateCommunity.tsx">
        <Dialog.Overlay className="fixed z-[1069] inset-0 bg-black/5 data-[state=open]:animate-overlayShow" data-sentry-element="unknown" data-sentry-source-file="JoinPrivateCommunity.tsx" />

        <Dialog.Content className="fixed left-[50%] top-[50%] bg-white rounded-md w-[90vw] md:w-[480px] z-[1069] translate-x-[-50%] translate-y-[-50%] focus:outline-none flex flex-col justify-center items-center px-12 py-12" data-sentry-element="unknown" data-sentry-source-file="JoinPrivateCommunity.tsx">
          <Dialog.Title className="text-purple-brand font-medium text-3xl md:text-3xlxl mb-4	" data-sentry-element="unknown" data-sentry-source-file="JoinPrivateCommunity.tsx">
            Join Community
          </Dialog.Title>

          <Dialog.Description className="font-normal text-base mb-10 text-[#49404C] text-center" data-sentry-element="unknown" data-sentry-source-file="JoinPrivateCommunity.tsx">
            Join{' '}
            <span className="text-purple-brand font-medium text-base">
              {modalType.extraData.communityName}
            </span>{' '}
            to interact with the post and be a part of the community.
          </Dialog.Description>

          <Dialog.Close data-sentry-element="unknown" data-sentry-source-file="JoinPrivateCommunity.tsx">
            <Button variant="ghost" className="absolute w-8 h-8 right-6 top-6 z-20 inline-flex p-0 appearance-none items-center justify-center focus:outline-none outline-none cursor-pointer hover:bg-none" aria-label="Close" data-sentry-element="Button" data-sentry-source-file="JoinPrivateCommunity.tsx">
              <X className="text-[#7F7186] text-xl cursor-pointer" data-sentry-element="X" data-sentry-source-file="JoinPrivateCommunity.tsx" />
            </Button>
          </Dialog.Close>

          <div className="flex w-full gap-4 items-center ">
            <Button className="w-full h-10" variant={'outline'} onClick={() => closeModal()} data-sentry-element="Button" data-sentry-source-file="JoinPrivateCommunity.tsx">
              Cancel
            </Button>

            <div className="flex items-center justify-center rounded-md bg-purple-brand w-full text-center h-10">
              <Link className="w-full text-center text-white" href={`/community/${modalType.extraData.communitySlug}`} data-sentry-element="Link" data-sentry-source-file="JoinPrivateCommunity.tsx">
                Join
              </Link>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>;
};
export default JoinPrivateCommunity;