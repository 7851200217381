'use client';

import React from 'react';
import { SearchInput } from '@/components/atom/search-input';
import { Button } from '@/components/atom/button';
import { FunnelIcon, SendPost } from '@/components/icons';
import Avatar from '@/components/atom/avatar';
import { cn } from '@/lib/utils';
import { ChevronLeft, Loader2Icon } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';
import useGetUserJoinedCommunitiesQuery from '@/hooks/react-query/communities/queries/useGetUserJoinedCommunitiesQuery';
import InfiniteScrollContainer from '@/components/InfiniteScrollContainer';
import { CommunityType } from '@/types';
// import AvatarList from './avaterList';
import ModalNew from '@/components/atom/modal-new';
import { X } from 'lucide-react';
import EmptyContent from '@/components/empty-content/empty-content';
import { Skeleton } from '@/components/atom/skeleton';
import { formatQuantityText } from '@/utils/newHelpers';
const CommunityItem = ({
  onClick,
  active,
  data
}: {
  active: boolean;
  onClick?: () => void;
  data: CommunityType;
}) => <Button onClick={onClick} variant="ghost" className={cn('flex w-full justify-start h-fit items-center gap-4 py-1 px-2 rounded-sm', active && 'shadow-img bg-pink-brand hover:bg-pink-brand')} data-sentry-element="Button" data-sentry-component="CommunityItem" data-sentry-source-file="SelectCommunityModal.tsx">
    <Avatar className="h-14 w-14 cursor-pointer" src={data?.imageUrl} fallbackText={data?.name} data-sentry-element="Avatar" data-sentry-source-file="SelectCommunityModal.tsx" />
    <div className="space-y-1 flex flex-col items-start">
      <p className="text-sm text-black-brand cursor-pointer">{data?.name}</p>
      <p className="text-xs text-gray-brand5 text-justify cursor-pointer">
        {/* {data?.memberAvatarUrls && data.memberAvatarUrls.length > 0 && (
          <AvatarList imageUrls={data?.memberAvatarUrls?.slice(0, 4) ?? []} />
         )}{' '} */}
        {formatQuantityText(data.membersCount, `member`)}
      </p>
    </div>
  </Button>;
const CommunityJoinedLoader = () => {
  return <div className={cn('flex w-full justify-start h-fit items-center gap-4 py-1 px-2 rounded-sm')} data-sentry-component="CommunityJoinedLoader" data-sentry-source-file="SelectCommunityModal.tsx">
      <Skeleton className="h-14 w-14 rounded-full" data-sentry-element="Skeleton" data-sentry-source-file="SelectCommunityModal.tsx" />
      <div className="space-y-1 flex flex-col items-start">
        <Skeleton className="w-28 h-3" data-sentry-element="Skeleton" data-sentry-source-file="SelectCommunityModal.tsx"></Skeleton>
        <Skeleton className="w-20 h-3" data-sentry-element="Skeleton" data-sentry-source-file="SelectCommunityModal.tsx"></Skeleton>
      </div>
    </div>;
};
const SelectCommunityModal = ({
  open,
  onOpenChange,
  onFinishPost,
  loading
}: {
  open: boolean;
  onFinishPost: (id: number) => void;
  onOpenChange: () => void;
  loading: boolean;
}) => {
  const {
    toast
  } = useToast();
  const [search, setSearch] = React.useState('');
  const [selected, setSelected] = React.useState<null | number>(null);
  const {
    data,
    isLoading,
    isFetchingNextPage,
    isFetching,
    hasNextPage,
    fetchNextPage
  } = useGetUserJoinedCommunitiesQuery({
    Search: search
  });
  const handleSubmit = () => {
    if (selected) {
      onFinishPost(selected);
    } else {
      toast({
        description: 'Please select a community to post to',
        variant: 'destructive'
      });
    }
  };
  return <ModalNew isOpen={open} onClose={onOpenChange} asDrawerOnMobile={true} classNames={{
    content: 'w-screen md:w-[60vw] md:max-w-[800px] h-screen md:h-[80vh] max-h-screen md:max-h-[80vh] px-0 md:px-8 py-1 md:py-4 rounded-none md:rounded-lg',
    footer: 'border-t-0',
    title: 'p-3 md:p-6',
    childrenContainer: `p-3 md:p-6`
  }} title={<div className="flex justify-between items-center w-full">
          <div className="flex items-center gap-1">
            <Button variant="ghost" className="block md:hidden p-0 appearance-none focus:outline-none" aria-label="Close" onClick={onOpenChange}>
              <ChevronLeft size={16} />
            </Button>

            <h5 className="m-0 p-0 text-lg md:text-2xl font-medium text-purple-brand3">
              Select a community to post in
            </h5>
          </div>

          <Button variant="ghost" className="hidden md:flex h-[25px] w-[25px] p-0 appearance-none focus:outline-none" aria-label="Close" onClick={onOpenChange}>
            <X size={20} />
          </Button>
        </div>} description="" hasCloseButton={false} footer={<div className="flex items-center justify-end w-full">
          <Button disabled={!selected || loading} className="w-full md:w-fit !disabled:cursor-not-allowed" onClick={handleSubmit}>
            {loading ? <>
                <Loader2Icon size={20} className="animate-spin" />
                &nbsp;Please wait...
              </> : <>
                <SendPost />
                &nbsp;Post
              </>}{' '}
          </Button>
        </div>} data-sentry-element="ModalNew" data-sentry-component="SelectCommunityModal" data-sentry-source-file="SelectCommunityModal.tsx">
      <div className="flex flex-col h-full gap-8 w-full">
        <section className="flex items-center gap-4 w-full">
          <div className="w-full max-w-[312px]">
            <SearchInput placeholder="Search for community" handleChange={v => setSearch(v)} data-sentry-element="SearchInput" data-sentry-source-file="SelectCommunityModal.tsx" />
          </div>

          <Button variant="ghost" size="icon" className="rounded-full" data-sentry-element="Button" data-sentry-source-file="SelectCommunityModal.tsx">
            <FunnelIcon data-sentry-element="FunnelIcon" data-sentry-source-file="SelectCommunityModal.tsx" />
          </Button>
        </section>

        <section className="h-full w-full overflow-y-auto">
          <InfiniteScrollContainer onBottomReached={() => hasNextPage && !isFetching && fetchNextPage()} loading={isFetchingNextPage} className="overflow-y-auto grid grid-cols-1 md:grid-cols-2 gap-4" data-sentry-element="InfiniteScrollContainer" data-sentry-source-file="SelectCommunityModal.tsx">
            {isLoading ? <>
                <CommunityJoinedLoader />
                <CommunityJoinedLoader />
                <CommunityJoinedLoader />
                <CommunityJoinedLoader />
                <CommunityJoinedLoader />
                <CommunityJoinedLoader />
              </> : data && data?.length > 0 ? <>
                {data?.map((v: CommunityType) => <CommunityItem key={v.id} data={v} active={selected === v.id} onClick={() => setSelected(prev => prev !== v.id ? v.id : null)} />)}
              </> : <div className="w-full h-full flex items-center justify-center md:col-span-2">
                <EmptyContent title={search !== '' ? 'No communities match that search' : 'You have not joined any communities yet.'} />
              </div>}
          </InfiniteScrollContainer>
        </section>
      </div>
    </ModalNew>;
};
export default SelectCommunityModal;