'use client';

import React from 'react';
import Post from '@/molecules/communities/Post';
import InfiniteScrollContainer from '@/components/InfiniteScrollContainer';
import EmptyContent from '@/components/empty-content/empty-content';
import { PostCallEnum, SortOrderEnum, SortPostByEnum } from '@/utils/enums';
import PostListLoading from '../feeds/PostListLoading';
import useGetAllPosts from '@/hooks/react-query/posts/queries/useGetAllPosts';
const ExplorePostsList = () => {
  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = useGetAllPosts({
    SortBy: SortPostByEnum.Default,
    SortOrder: SortOrderEnum.Descending
  });
  if (isLoading) return <div className="w-full flex flex-col gap-6">
        <PostListLoading />
      </div>;
  if (!data || data?.length === 0) return <EmptyContent title="No post found" />;
  return <InfiniteScrollContainer loading={isFetchingNextPage} className="flex flex-col gap-6 w-full" onBottomReached={() => hasNextPage && !isFetching && fetchNextPage()} data-sentry-element="InfiniteScrollContainer" data-sentry-component="ExplorePostsList" data-sentry-source-file="ExplorePostList.tsx">
      {data?.map((post: any) => <Post data={post} key={post.id} timeAgo={post.createdOn} hashtags={post.tags ?? []} media={post.imageUrls || []} dataKey={PostCallEnum.Explore} />)}
    </InfiniteScrollContainer>;
};
export default ExplorePostsList;