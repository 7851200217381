import { Warning } from '@/components/icons';
import React from 'react';
const ValidateEmailFailed = ({
  onGoBack
}: {
  onGoBack: () => void;
}) => {
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onGoBack();
    }, 3000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div className="w-[334px] h-[240px] m-auto flex flex-col items-center justify-center my-10 lg:my-20" data-sentry-component="ValidateEmailFailed" data-sentry-source-file="ValidateEmailFailed.tsx">
      <div className="mb-8">
        <Warning filled data-sentry-element="Warning" data-sentry-source-file="ValidateEmailFailed.tsx" />
      </div>

      <h1 className="mb-1 text-2xl font-medium text-center text-purple-brand3">
        Validation failed
      </h1>
      <p className="text-base text-center text-gray-brand7">
        We are having issues validating your OTP.
        <br /> Please check the numbers and try again.
      </p>
    </div>;
};
export default ValidateEmailFailed;