import { toggleReadStatusNotification } from '@/services/notifications.service';
import { ToggleReadStatusNotificationPayloadType } from '@/types';
import { useMutation } from '@tanstack/react-query';

const useToggleReadStatusNotification = () => {
  return useMutation({
    mutationKey: ['ToggleReadStatusNotification'],
    mutationFn: (payload: ToggleReadStatusNotificationPayloadType) =>
      toggleReadStatusNotification(payload),
  });
};

export default useToggleReadStatusNotification;
