'use client';

import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/atom/dialog';
import { useRouter } from 'next/navigation';
import { Button } from '@/components/atom/button';
import useShopStore from '@/hooks/store/use-shop-store';
import useAppStore from '@/hooks/use-app-store';
import Img from '../atom/img';
export default function CreateShopItemSuccessModal() {
  const {
    openShareModal
  } = useAppStore(state => state);
  const router = useRouter();
  const {
    shopSuccess,
    setShopSuccess
  } = useShopStore();
  return <Dialog open={!!shopSuccess} onOpenChange={() => setShopSuccess(null)} data-sentry-element="Dialog" data-sentry-component="CreateShopItemSuccessModal" data-sentry-source-file="CreateShopItemSuccessModal.tsx">
      <DialogContent className="w-full p-0 antialiased flex flex-col items-center justify-between max-w-[446px] rounded-xl" data-sentry-element="DialogContent" data-sentry-source-file="CreateShopItemSuccessModal.tsx">
        <DialogHeader className="w-full text-start h-32" data-sentry-element="DialogHeader" data-sentry-source-file="CreateShopItemSuccessModal.tsx">
          <DialogTitle className="relative h-full w-full flex items-center" data-sentry-element="DialogTitle" data-sentry-source-file="CreateShopItemSuccessModal.tsx">
            <span className="sr-only">Event created</span>
            <Img src={shopSuccess?.base64 ?? ''} alt="community-img" className="w-full h-full object-cover" data-sentry-element="Img" data-sentry-source-file="CreateShopItemSuccessModal.tsx" />
            <div className="absolute inset-0 bg-purple-brand3/40"></div>

            <div className="absolute -bottom-[50%] left-[35%] border-white border-[4px]  h-32 w-32 min-w-32 rounded-lg">
              <Img src={shopSuccess?.base64 ?? ''} alt="community-img" className="w-full h-full object-cover inset-0 rounded-lg" data-sentry-element="Img" data-sentry-source-file="CreateShopItemSuccessModal.tsx" />
            </div>
          </DialogTitle>
        </DialogHeader>
        <div className="w-full h-full min-h-[200px] mt-8 overflow-y-auto p-4 md:p-10 flex flex-col items-center justify-center">
          <h1 className="text-2xl font-medium text-purple-brand3 text-center">Event Created</h1>
          <p className="text-sm text-gray-brand7 mb-8 text-center">
            You have successfully created a new event called{' '}
            <span className="font-medium">{shopSuccess?.name}</span>
          </p>
          <Button className="w-full mb-3" onClick={() => {
          router.replace(`/events/${shopSuccess?.slug}`);
          setShopSuccess(null);
        }} data-sentry-element="Button" data-sentry-source-file="CreateShopItemSuccessModal.tsx">
            Share on Community Feed
          </Button>
          <Button variant="outline" className="w-full text-purple-brand" onClick={() => {
          router.replace('/explore/shop');
          openShareModal({
            title: `Share ${shopSuccess?.name}`,
            path: `events/${shopSuccess?.slug}`
          });
          setShopSuccess(null);
        }} data-sentry-element="Button" data-sentry-source-file="CreateShopItemSuccessModal.tsx">
            Invite Others to Register
          </Button>
        </div>
      </DialogContent>
    </Dialog>;
}