import { CommentType } from '@/types';
import CommentItem from './CommentItem';
export default function CommentThread({
  comments,
  postSlug,
  isChild,
  dataKey
}: {
  dataKey: string;
  comments: CommentType[];
  postSlug: string;
  isChild?: boolean;
}) {
  return <ol className="relative border-l border-brand10 w-full mt-6" data-sentry-component="CommentThread" data-sentry-source-file="CommentThread.tsx">
      {comments?.map(comment => <CommentItem comment={comment} isChild={isChild} postSlug={postSlug} dataKey={dataKey} hideReply />)}
    </ol>;
}