'use client';

import React, { useEffect, useState } from 'react';
import { Button } from '@/components/atom/button';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import Image from 'next/image';
import MobileCTA from './MobileCTA';
import { useDeviceType } from '@/hooks/use-device-type';
import { MINUTES_BTW_CTA_POPUP } from '@/configs/global-variables';
const intervalDuration = MINUTES_BTW_CTA_POPUP * 60 * 1000;
const CTABanner = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const deviceType = useDeviceType();
  const hasSearchParams = searchParams.toString().length > 0;
  const [open, setOpen] = useState(hasSearchParams ? false : true);
  const openMobileCTA = () => {
    if (!hasSearchParams) setOpen(true);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      openMobileCTA();
    }, intervalDuration);

    // Cleanup function to clear the timer when the component unmounts
    return () => clearTimeout(timer);
  });
  return <div className="h-full w-full" data-sentry-component="CTABanner" data-sentry-source-file="CTABanner.tsx">
      {/* Desktop */}
      <div className="hidden h-full w-full p-0 md:flex justify-center bg-cta-gradient">
        <div className="md:max-w-[1200px] flex justify-between items-center w-full relative">
          <div className="flex-col gap-0 hidden md:flex ml-[232px]">
            <p className="text-white text-xl font-semibold">Make posts, get paid on Fusion.</p>
            <p className="text-white text-sm font-normal">Sign up and earn right now!</p>
          </div>

          <div className="flex gap-6 items-center justify-between md:justify-start w-full md:w-fit px-4 md:px-0 z-20">
            <Button className="w-full md:w-36 text-purple-brand bg-white font-semi-bold hover:bg-slate-100 hover:text-black px-12" onClick={() => router.push('/signup')} data-sentry-element="Button" data-sentry-source-file="CTABanner.tsx">
              Sign up
            </Button>

            <Button variant="outline" className="w-full md:w-36 text-white bg-transparent border border-white py-1 font-semibold px-12 hover:bg-slate-100" onClick={() => router.push(`${pathname}?ref=signin`)} data-sentry-element="Button" data-sentry-source-file="CTABanner.tsx">
              Log in
            </Button>
          </div>
        </div>

        <>
          <div className="absolute left-0 top-0 hidden md:block">
            <Image src={'/assets/background/cta_web_left.svg'} alt="banner background" width={20} height={20} className="w-20 h-20" data-sentry-element="Image" data-sentry-source-file="CTABanner.tsx" />
          </div>
          <div className="absolute right-0 top-0 hidden md:block">
            <Image src={'/assets/background/cta_web_right.svg'} alt="banner background" width={20} height={20} className="w-20 h-20" data-sentry-element="Image" data-sentry-source-file="CTABanner.tsx" />
          </div>
        </>
      </div>

      {/* Mobile */}
      {open && deviceType === 'phone' && <MobileCTA isOpen={open} onClose={() => {
      setTimeout(() => {
        openMobileCTA();
      }, intervalDuration);
      setOpen(false);
    }} />}
    </div>;
};
export default CTABanner;