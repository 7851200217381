import { Skeleton } from '@/components/atom/skeleton';
import React from 'react';
const CommunitySummaryLoader = () => {
  return <div className="flex flex-col flex-grow gap-4 w-full p-6 bg-white space-y-10 rounded-lg" data-sentry-component="CommunitySummaryLoader" data-sentry-source-file="CommunitySummaryLoader.tsx">
      <div className="flex flex-col gap-4">
        <Skeleton className="w-full max-w-[158px] h-[18px] rounded-[68.97px]" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
        <div className="flex items-center gap-2">
          <Skeleton className="w-8 h-8 rounded-full min-w-8" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
          <div className="w-full space-y-1">
            <Skeleton className="w-full h-4" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
            <Skeleton className="w-full max-w-[84px] h-2.5" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Skeleton className="w-8 h-8 rounded-full min-w-8" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
          <div className="w-full space-y-1">
            <Skeleton className="w-full h-4" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
            <Skeleton className="w-full max-w-[84px] h-2.5" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Skeleton className="w-8 h-8 rounded-full min-w-8" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
          <div className="w-full space-y-1">
            <Skeleton className="w-full h-4" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
            <Skeleton className="w-full max-w-[84px] h-2.5" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <Skeleton className="w-full max-w-[158px] h-[18px] rounded-[68.97px]" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
        <div className="flex items-center gap-2">
          <Skeleton className="w-8 h-8 rounded-full min-w-8" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
          <div className="w-full space-y-1">
            <Skeleton className="w-full h-4" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
            <Skeleton className="w-full max-w-[84px] h-2.5" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Skeleton className="w-8 h-8 rounded-full min-w-8" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
          <div className="w-full space-y-1">
            <Skeleton className="w-full h-4" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
            <Skeleton className="w-full max-w-[84px] h-2.5" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Skeleton className="w-8 h-8 rounded-full min-w-8" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
          <div className="w-full space-y-1">
            <Skeleton className="w-full h-4" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
            <Skeleton className="w-full max-w-[84px] h-2.5" data-sentry-element="Skeleton" data-sentry-source-file="CommunitySummaryLoader.tsx" />
          </div>
        </div>
      </div>
    </div>;
};
export default CommunitySummaryLoader;