import { cn } from '@/lib/utils';
import React from 'react';
import { useInView } from 'react-intersection-observer';
export interface InfiniteScrollContainerProps extends React.PropsWithChildren {
  onBottomReached: () => void;
  loading?: boolean;
  className?: string;
}

/**
 *
 * @prop Children ReactNode
 * @prop onBottomReached () => void
 * @prop loading? boolean
 * @prop className? ReactClassNames
 * @returns ReactNode
 */
const InfiniteScrollContainer = ({
  onBottomReached,
  className,
  children,
  loading
}: InfiniteScrollContainerProps) => {
  const {
    ref
  } = useInView({
    rootMargin: '200px',
    onChange(inView) {
      if (inView) {
        onBottomReached();
      }
    }
  });
  return <section className={cn('w-full', className)} data-sentry-component="InfiniteScrollContainer" data-sentry-source-file="InfiniteScrollContainer.tsx">
      {children}
      {loading ? <p className="text-center text-sm font-light">Loading more...</p> : <div className="invisible w-full" ref={ref}>
          <span className="sr-only">Bottom threshold</span>
          Bottom threshold
        </div>}
    </section>;
};
export default InfiniteScrollContainer;