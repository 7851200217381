import axios from 'axios';
import { FUSION_API } from './global-variables';
import { getSession } from 'next-auth/react';
const removeFalsyParams = (params: Record<string, any>): Record<string, any> => {
  return Object.keys(params).reduce(
    (acc, key) => {
      const value = params[key];
      if (value || typeof value === 'boolean') {
        // This will filter out falsy values like null, undefined, 0, ''
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, any>
  );
};

const paramsSerializer = (params: Record<string, string>): string => {
  const searchParams = new URLSearchParams();
  const cleanedParams = removeFalsyParams(params);

  for (const key in cleanedParams) {
    const value = cleanedParams[key];

    if (Array.isArray(value)) {
      // Serialize arrays with the '[]' notation
      value.forEach((item) => {
        searchParams.append(key, item);
      });
    } else {
      // Serialize other values
      searchParams.append(key, value);
    }
  }

  return searchParams.toString();
};

export const axiosClientInstance = axios.create({
  baseURL: FUSION_API.apiMicroserviceAdmin,
  paramsSerializer,
  headers: {
    'Content-Type': 'application/json',
    'X-Request-Source': 'configure-client',
  },
});

// Add a request interceptor
axiosClientInstance.interceptors.request.use(
  async function (config) {
    const session =
      getSession && typeof getSession === 'function' ? await getSession() : { token: '' };
    // Do something before request is sent
    if (session?.token) {
      config.headers['Authorization'] = `Bearer ${session?.token}`;
    }
    if (config.logger) {
      console.log(config);
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
