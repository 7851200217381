export function convertTo24HourFormat(time: string): string {
  // Ensure the input is valid
  const timeRegex = /^([0-1]?[0-9]|2[0-3]):([0-5]?[0-9])(AM|PM)$/;
  const match = time.match(timeRegex);

  if (!match) {
    throw new Error("Invalid time format. Expected format is 'hh:mm AM/PM'.");
  }

  // Extract hours, minutes, and period (AM/PM)
  let hours: number = parseInt(match[1], 10); // hours as a number
  let minutes: number = parseInt(match[2], 10); // minutes as a number
  const period: string = match[3]; // period (AM/PM) as a string

  // Convert based on AM/PM
  if (period === 'AM') {
    if (hours === 12) {
      // 12 AM is 00:xx
      hours = 0;
    }
  } else if (period === 'PM') {
    if (hours !== 12) {
      // PM times other than 12 are hours + 12 (e.g. 1 PM -> 13)
      hours += 12;
    }
  }

  // Format hours and minutes to ensure two digits
  const hours24: string = hours.toString().padStart(2, '0');
  const minutes24: string = minutes.toString().padStart(2, '0');

  // Return in 24-hour format
  return `${hours24}:${minutes24}`;
}

export function calculateTimeRange(startTime: string, duration: string): string {
  // Parse start time
  const [startHours, startMinutes, period] = startTime.match(/(\d+):(\d+)([AP]M)/)!.slice(1);
  let hours = parseInt(startHours);
  const minutes = parseInt(startMinutes);

  // Convert to 24-hour format
  if (period === 'PM' && hours !== 12) hours += 12;
  if (period === 'AM' && hours === 12) hours = 0;

  // Parse duration
  const [durationHours, durationMinutes] = duration.split(':').map(Number);

  // Calculate end time
  let endHours = hours + durationHours;
  let endMinutes = minutes + durationMinutes;

  // Adjust for overflow
  if (endMinutes >= 60) {
    endHours += Math.floor(endMinutes / 60);
    endMinutes %= 60;
  }
  endHours %= 24;

  // Convert back to 12-hour format
  const endPeriod = endHours >= 12 ? 'PM' : 'AM';
  endHours = endHours % 12 || 12;

  // Format the result
  const formatTime = (h: number, m: number, p: string) =>
    `${h}:${m.toString().padStart(2, '0')}${p}`;

  const res = `${startTime} - ${formatTime(endHours, endMinutes, endPeriod)}`;
  return res;
}

export function setTimeOnDateAndReturnISO(date: Date, time: string): string {
  // Ensure time matches the expected format (e.g., 12:00PM)
  // const [startHours, startMinutes, period] = time?.match(/(\d+):(\d+)([AP]M)/)!.slice(1);

  // let hours = parseInt(startHours) + 1;
  // const minutes = parseInt(startMinutes);

  // // Convert to 24-hour format
  // if (period === 'PM' && hours !== 12) hours += 12;
  // if (period === 'AM' && hours === 12) hours = 0;

  const hhMM = convertTo24HourFormat(time);
  const [hours, minutes] = hhMM.split(':');
  return new Date(
    new Date(date).setHours(parseInt(hours) + 1, parseInt(minutes), 0, 0)
  ).toISOString();
}
