import { create } from 'zustand';

type CommunitySuccessType = {
  base64: string;
  name: string;
  id: number;
  slug: string;
};
type TransferCommunitySuccessType = {
  communityName?: string;
  adminName?: string;
  price?: string;
};

type DeactivateCommunityPayloadType = {
  open: boolean;
  actionType: string;
};

type CommunityStoreType = {
  // Update community states
  updateSuccessful: boolean;
  toggleUpdateSuccessful: () => void;

  // End update community states

  // Transfer Community states
  transferCommunitySuccessPayload: TransferCommunitySuccessType | null;
  transferOwnershipSuccessful: boolean;
  toggleTransferOwnershipSuccessful: () => void;

  openTransferOwnership: boolean;
  toggleOpenTransferOwnership: () => void;
  setTransferCommunitySuccessPayload: (v: TransferCommunitySuccessType) => void;
  // End transfer community states

  // Deactivate Community states
  deactivateCommunitySuccessPayload: DeactivateCommunityPayloadType;
  deactivateCommunityPayload: DeactivateCommunityPayloadType;
  setDeactivateCommunityPayload: (v: DeactivateCommunityPayloadType) => void;
  setDeactivateCommunitySuccessPayload: (v: DeactivateCommunityPayloadType) => void;
  // End deactivate community states

  // Create Community Success states
  communitySuccess: CommunitySuccessType | null;
  setCommunitySuccess: (val: CommunitySuccessType | null) => void;
  // End create community success states

  selectedAdminAction: number;
  setSelectedAdminAction: (v: number) => void;
};

const useCommunityStore = create<CommunityStoreType>((set) => ({
  selectedAdminAction: 0,
  setSelectedAdminAction: (payload) =>
    set(() => ({
      selectedAdminAction: payload,
    })),
  transferCommunitySuccessPayload: null,
  updateSuccessful: false,
  openTransferOwnership: false,
  transferOwnershipSuccessful: false,
  communitySuccess: null,
  deactivateCommunityPayload: { open: false, actionType: '' },
  deactivateCommunitySuccessPayload: { open: false, actionType: '' },
  setDeactivateCommunityPayload: (payload) =>
    set(() => ({
      deactivateCommunityPayload: payload,
    })),
  setDeactivateCommunitySuccessPayload: (payload) =>
    set(() => ({
      deactivateCommunitySuccessPayload: payload,
      deactivateCommunityPayload: { open: false, actionType: '' },
    })),
  setCommunitySuccess: (payload) =>
    set(() => ({
      communitySuccess: payload,
    })),
  toggleOpenTransferOwnership: () =>
    set((prev) => ({
      openTransferOwnership: !prev.openTransferOwnership,
    })),
  toggleUpdateSuccessful: () =>
    set((prev) => ({
      updateSuccessful: !prev.updateSuccessful,
    })),
  toggleTransferOwnershipSuccessful: () =>
    set((prev) => ({
      transferOwnershipSuccessful: !prev.transferOwnershipSuccessful,
    })),
  setTransferCommunitySuccessPayload: (payload: Partial<TransferCommunitySuccessType>) =>
    set((prev) => ({
      transferCommunitySuccessPayload: { ...prev.transferCommunitySuccessPayload, ...payload },
      deactivateCommunityPayload: { open: false, actionType: '' },
    })),
}));

export default useCommunityStore;
