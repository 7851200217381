'use client';

import * as React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Button } from './button';
import { useDeviceType } from '@/hooks/use-device-type';
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string | React.ReactNode;
  description?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  fullScreenScroll?: boolean;
  classNames?: {
    content?: string;
    footer?: string;
    title?: string;
    childrenContainer?: string;
  };
  hasCloseButton?: boolean;
  asDrawerOnMobile?: boolean;
  useMobileView?: boolean;
}
export default function ModalNew({
  isOpen,
  onClose,
  title,
  description,
  children,
  footer,
  fullScreenScroll = false,
  classNames,
  hasCloseButton = true,
  asDrawerOnMobile = true,
  useMobileView = true
}: ModalProps) {
  const deviceType = useDeviceType();
  return <Dialog.Root open={isOpen} onOpenChange={onClose} data-sentry-element="unknown" data-sentry-component="ModalNew" data-sentry-source-file="modal-new.tsx">
      <Dialog.Portal data-sentry-element="unknown" data-sentry-source-file="modal-new.tsx">
        {(!useMobileView || deviceType !== 'phone') && <Dialog.Overlay className="fixed z-[1069] inset-0 bg-black/50 data-[state=open]:animate-overlayShow" />}
        <Dialog.Content className={cn('fixed z-[1070] left-[50%] top-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-lg bg-white shadow-card focus:outline-none data-[state=open]:animate-contentShow flex flex-col', deviceType !== 'phone' && 'fixed left-[50%] top-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-lg bg-white shadow-card focus:outline-none data-[state=open]:animate-contentShow flex flex-col', !useMobileView && deviceType === 'phone' && asDrawerOnMobile && 'w-screen h-[calc(100vh-56px)] fixed bottom-0 max-h-[calc(100vh-56px)] z-[1069] bg-white flex flex-col', classNames?.content)} data-sentry-element="unknown" data-sentry-source-file="modal-new.tsx">
          <div className={cn('p-[25px] pb-4', classNames?.title)}>
            <Dialog.Title className="m-0 text-lg md:text-2xl font-medium text-purple-brand3" data-sentry-element="unknown" data-sentry-source-file="modal-new.tsx">
              {title}
            </Dialog.Title>
            {description && <Dialog.Description className="mt-[10px] mb-5 text-xs text-gray-brand7">
                {description}
              </Dialog.Description>}
            {hasCloseButton && <Dialog.Close asChild>
                <Button variant="ghost" className="absolute right-4 top-4 inline-flex h-[25px] w-[25px] p-0 appearance-none items-center justify-center rounded-full focus:outline-none" aria-label="Close">
                  <X />
                </Button>
              </Dialog.Close>}
          </div>

          <div className={cn(`flex-grow ${fullScreenScroll ? 'overflow-y-auto' : 'overflow-y-auto'} px-[25px]`, classNames?.childrenContainer)}>
            {children}
          </div>

          {footer && <div className={cn('mt-auto p-[25px] pt-4 gap-[10px] border-t', classNames?.footer)}>
              {footer}
            </div>}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>;
}