'use client';

import * as React from 'react';
import { OTPInput, OTPInputContext } from 'input-otp';
import { Minus } from 'lucide-react';
import { cn } from '@/lib/utils';
const InputOTP = React.forwardRef<React.ElementRef<typeof OTPInput>, React.ComponentPropsWithoutRef<typeof OTPInput>>(({
  className,
  containerClassName,
  ...props
}, ref) => <OTPInput ref={ref} containerClassName={cn('flex items-center gap-2 has-[:disabled]:opacity-50', containerClassName)} className={cn('disabled:cursor-not-allowed', className)} {...props} />);
InputOTP.displayName = 'InputOTP';
const InputOTPGroup = React.forwardRef<React.ElementRef<'div'>, React.ComponentPropsWithoutRef<'div'>>(({
  className,
  ...props
}, ref) => <div ref={ref} className={cn('flex items-center gap-2', className)} {...props} />);
InputOTPGroup.displayName = 'InputOTPGroup';
const InputOTPSlot = React.forwardRef<React.ElementRef<'div'>, React.ComponentPropsWithoutRef<'div'> & {
  index: number;
  placeholder?: string;
  caretClassName?: string;
  mask?: boolean;
}>(({
  index,
  placeholder,
  className,
  caretClassName,
  mask,
  ...props
}, ref) => {
  const inputOTPContext = React.useContext(OTPInputContext);
  const {
    char,
    hasFakeCaret,
    isActive
  } = inputOTPContext.slots[index];
  return <div ref={ref} className={cn('relative flex h-14 w-14 items-center justify-center border border-gray-brand2 text-gray-brand5 bg-white text-4xl leading-[44px] transition-all  rounded-sm', isActive && 'z-10 ring-2 ring-primary ring-offset-purple-brand3', className)} {...props}>
      {char ? mask ? <span className="text-xl translate-y-1">*</span> : char : !hasFakeCaret && placeholder && <span className="text-gray-brand2">{placeholder[0]}</span>}
      {hasFakeCaret && <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
          <div className={cn('w-px h-10 duration-1000 animate-caret-blink bg-foreground', caretClassName)} />
        </div>}
    </div>;
});
InputOTPSlot.displayName = 'InputOTPSlot';
const InputOTPSeparator = React.forwardRef<React.ElementRef<'div'>, React.ComponentPropsWithoutRef<'div'>>(({
  ...props
}, ref) => <div ref={ref} role="separator" {...props}>
    <Minus className="w-5 text-gray-brand2" />
  </div>);
InputOTPSeparator.displayName = 'InputOTPSeparator';
export { InputOTP, InputOTPGroup, InputOTPSlot, InputOTPSeparator };