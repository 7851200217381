import { markAllAsReadMutation } from '@/services/notifications.service';
import { MarkAllNotificationsAsReadPayloadType } from '@/types';
import { useMutation } from '@tanstack/react-query';

const useMarkAllAsReadMutation = () => {
  return useMutation({
    mutationKey: ['MarkAllNotificationsAsRead'],
    mutationFn: (payload: MarkAllNotificationsAsReadPayloadType) => markAllAsReadMutation(payload),
  });
};

export default useMarkAllAsReadMutation;
