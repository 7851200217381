'use client';

import React from 'react';
import ModalNew from '../atom/modal-new';
import { useRouter, useSearchParams, usePathname } from 'next/navigation';
import { MODAL_PARAMS, TicketTypeOptions } from '@/constants';
import Img from '../atom/img';
import useTipTapEditor from '@/hooks/use-tiptap-editor';
import { EditorContent } from '@tiptap/react';
import { ImageGalleryAdd } from '../icons';
import useMediaCapture from '@/hooks/use-media-capture';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PictureTaker from '../PictureTaker';
import { Button } from '../atom/button';
import { EditEventTitleModel } from '@/lib/models';
import { editEventTitleSchema } from '@/lib/schemas';
import { Loader2, Tag, Trash2 } from 'lucide-react';
import { Input } from '../atom/input';
import { separateThousand } from '@/lib/separateThousand';
import { TicketType } from '@/utils/enums';
import RadioButton from '../atom/radio-button';
import { cn } from '@/lib/utils';
import useGetEventItemBySlug from '@/hooks/react-query/shop/queries/useGetEventItemBySlug';
import base64ToFile from '@/lib/base64-to-file';
import { imageUrlToBase64 } from '@/lib/url-to-base64';
import useUpdateEventMutation from '@/hooks/react-query/shop/mutations/useUpdateEventTitleMutation';
import { UpdateEventTitleFormType } from '@/types';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '@/hooks/use-toast';
const EditEventTitleModal = () => {
  const {
    toast
  } = useToast();
  const queryClient = useQueryClient();
  const router = useRouter();
  const params = useSearchParams();
  const pathname = usePathname();
  const eventId = params.get(MODAL_PARAMS.editEventId);
  const eventQuery = useGetEventItemBySlug({
    Slug: eventId ?? ''
  });
  const {
    editor,
    value
  } = useTipTapEditor({
    placeholder: 'Write a short description of your event (Maximum number of words: 150)',
    className: '!min-h-[103px]',
    defaultContent: eventQuery.data?.description ?? ''
  });
  const [previousValues, setPreviousValues] = React.useState<any>({});
  const {
    capturedImage,
    imgRef,
    handleFileInput,
    canvasRef,
    imageFile,
    setCapturedImage
  } = useMediaCapture();
  const form = useForm<EditEventTitleModel>({
    resolver: yupResolver(editEventTitleSchema)
  });
  const ticketTypeWatcher = form.watch('ticketType');
  const updateEventMutation = useUpdateEventMutation();
  const onSubmit = () => {
    const formValues = form.getValues();
    const splitUrl = eventQuery.data?.imageUrls[0]?.split('/');
    const prevFileName = splitUrl[splitUrl.length - 1];
    const payload: UpdateEventTitleFormType = {
      ...formValues,
      eventId: eventQuery.data?.id,
      price: Number(formValues.price),
      numbersAvailable: Number(formValues.numbersAvailable),
      ticketType: Number(formValues.ticketType),
      // communityId: Number(formValues.communityId),
      base64: prevFileName !== formValues?.imageFile?.name && formValues.base64 ? formValues.base64.split(',').pop() : ''
    };
    console.log;
    updateEventMutation.mutate(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['get-event-item-by-slug']
        });
        router.replace(pathname);
        toast({
          variant: 'success',
          title: 'Changes Saved',
          description: 'The changes you made to the event details and description have been saved.'
        });
      },
      onError: () => {
        toast({
          variant: 'destructive',
          title: 'Unable to save changes',
          description: 'The changes you made could not be saved.'
        });
      }
    });
  };
  const areObjectsEqual = (obj1: any, obj2: any) => {
    return Object.keys(obj1).every(key => obj1[key] === obj2[key]);
  };
  React.useEffect(() => {
    if (capturedImage && imageFile) {
      form.setValue('base64', capturedImage);
      form.setValue('imageFile', imageFile);
      form.trigger('imageFile');
    }
  }, [capturedImage]);
  React.useEffect(() => {
    if (value) {
      form.setValue('description', value);
    }
  }, [value]);
  React.useEffect(() => {
    if (!eventQuery.isLoading && eventQuery.data) {
      const imgUrl = eventQuery.data?.imageUrls[0] ?? '';
      imageUrlToBase64(imgUrl).then(val => {
        form.setValue('base64', val);
        setCapturedImage(val);
        const splitUrl = imgUrl.split('/');
        const fileName = splitUrl[splitUrl.length - 1];
        const file = base64ToFile(val, fileName);
        form.setValue('imageFile', file);
        form.trigger('imageFile');
        form.trigger('base64');
      }).finally(() => {
        const resData = eventQuery.data;
        form.setValue('description', resData.description);
        // setValue(resData.description);
        form.setValue('eventName', resData.title);
        form.setValue('ticketType', `${resData.isPaidEvent ? TicketType.Paid : TicketType.Free}`);
        if (resData.isPaidEvent) {
          form.setValue('price', `${resData.cost}`);
          form.setValue('numbersAvailable', `${resData.maximumAttendees}`);
        }
        setPreviousValues(form.getValues());
      });
      form.trigger('imageFile');
    }
  }, [eventQuery.isLoading, eventQuery.data]);
  if (eventQuery.isLoading) return <div>Loading...</div>;
  return <ModalNew isOpen={params.get(MODAL_PARAMS.type) === MODAL_PARAMS.eventTitle} onClose={() => router.replace(pathname)} asDrawerOnMobile={true} title="Edit title and description" description="" classNames={{
    content: 'w-full max-w-[440px]'
  }} footer={<div className="flex flex-col md:flex-row justify-between gap-4 items-center w-full">
          <Button type="button" className="w-full" onClick={() => {
      router.replace(pathname);
      if (eventQuery.isLoading) {
        queryClient.cancelQueries({
          queryKey: ['get-event-item-by-slug']
        });
      }
    }} variant="outline" disabled={updateEventMutation.isPending}>
            Cancel
          </Button>

          <Button type="button" className="w-full" disabled={areObjectsEqual(previousValues, form.watch()) || updateEventMutation.isPending || eventQuery.isLoading} onClick={() => form.handleSubmit(onSubmit)()}>
            {updateEventMutation.isPending ? <>
                <Loader2 className="animate-spin mr-2" size={18} /> Processing...
              </> : 'Save Changes'}
          </Button>
        </div>} data-sentry-element="ModalNew" data-sentry-component="EditEventTitleModal" data-sentry-source-file="EditEventTitleModal.tsx">
      <form className="w-full flex flex-col gap-4 mb-10">
        <div className="">
          <label htmlFor="image">Upload an event banner</label>

          <div className="flex flex-col gap-4">
            <label htmlFor="file-upload" className={cn(' cursor-pointer h-[216px] shadow-img bg-gray-brand4 relative flex items-center justify-center border-gray-300 rounded-lg', form.formState.errors?.imageFile?.message && 'bg-red-50 border border-destructive')}>
              {(capturedImage || form.watch('base64')) && <>
                  <button type="button" className="text-destructive absolute top-0 -right-0 z-30" onClick={e => {
                setCapturedImage('');
                form.setValue('base64', '');
                form.setValue('imageFile', ((undefined as unknown) as File));
              }}>
                    <Trash2 size={16} />
                  </button>
                  <Img src={capturedImage || form.watch('base64') || ''} alt="upload-media" fill className="absolute top-0 bottom-0 left-0 right-0 object-cover z-20" />

                  <canvas ref={canvasRef} width={640} height={480} className="hidden" />
                </>}
              <input type="file" id="file-upload" className="hidden h-full w-full absolute top-0 bottom-0 right-0 left-0 z-10" accept="image/*" disabled={updateEventMutation.isPending || eventQuery.isLoading} ref={imgRef} onChange={handleFileInput} />

              <ImageGalleryAdd data-sentry-element="ImageGalleryAdd" data-sentry-source-file="EditEventTitleModal.tsx" />
            </label>
            {form.formState.errors?.imageFile?.message && <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
                {form.formState.errors?.imageFile?.message}
              </p>}
            <div className="flex items-center justify-center w-full gap-4 rounded-sm">
              <Button type="button" variant="outline" className="w-full text-purple-brand bg-white" onClick={() => imgRef.current?.click()} disabled={updateEventMutation.isPending || eventQuery.isLoading} data-sentry-element="Button" data-sentry-source-file="EditEventTitleModal.tsx">
                Browse Gallery
              </Button>
              <PictureTaker disabled={updateEventMutation.isPending || eventQuery.isLoading} onCaptureImage={(imageSrc, file) => {
              form.setValue('base64', (imageSrc as string));
              if (file) {
                form.setValue('imageFile', file);
              }
              setCapturedImage((imageSrc as string));
            }} type="landscape" data-sentry-element="PictureTaker" data-sentry-source-file="EditEventTitleModal.tsx" />
            </div>
          </div>
        </div>

        <div className="w-full">
          <label className="text-sm font-medium" htmlFor="eventName">
            Name of event
          </label>
          <Input {...form.register('eventName')} errorMessage={form.formState.errors.eventName?.message} disabled={updateEventMutation.isPending || eventQuery.isLoading} data-sentry-element="Input" data-sentry-source-file="EditEventTitleModal.tsx" />
        </div>

        <div className="w-full mb-4">
          <label className="text-sm font-medium" htmlFor="username">
            Description
          </label>

          <EditorContent editor={editor} disabled={updateEventMutation.isPending || eventQuery.isLoading} data-sentry-element="EditorContent" data-sentry-source-file="EditEventTitleModal.tsx" />
          <p className="text-end text-xs text-gray-brand">
            {150 - value?.trim()?.length} of 150 words left
          </p>
        </div>

        <div className="w-full">
          <label htmlFor="ticketType">Ticket type</label>
          <div className="grid grid-cols-2 gap-x-4">
            <Controller name="ticketType" control={form.control} render={({
            field,
            fieldState: {
              error
            }
          }) => <>
                  {TicketTypeOptions.map(v => <RadioButton key={v.value} id={v.label} value={v.value} label={v.label} name={field.name} onChange={field.onChange} error={error?.message} checked={v.value.toString() === field.value} disabled={updateEventMutation.isPending || eventQuery.isLoading} />)}

                  {error?.message && <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
                      {error?.message}
                    </p>}
                </>} data-sentry-element="Controller" data-sentry-source-file="EditEventTitleModal.tsx" />
          </div>
        </div>

        {Number(ticketTypeWatcher) === TicketType.Paid && <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="price">Price</label>
              <div className="relative">
                <span className={cn('absolute text-black-brand text-sm left-3 top-1/2 -translate-y-1/2 z-10', form.formState.errors?.price?.message && 'top-[18px] text-destructive')}>
                  ₦
                </span>
                <Controller name="price" control={form.control} render={({
              field,
              fieldState: {
                error
              }
            }) => <Input {...field} value={separateThousand(field.value ?? '')} onChange={e => field.onChange(separateThousand(e.target.value))} className="pl-7 pr-8" errorMessage={error?.message} disabled={updateEventMutation.isPending || eventQuery.isLoading} />} />
                {!form.formState.errors?.price?.message && <Tag className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400" size={18} />}
              </div>
            </div>
            <div>
              <label htmlFor="numbersAvailable">Numbers available</label>
              <Controller name="numbersAvailable" control={form.control} render={({
            field,
            fieldState: {
              error
            }
          }) => <Input {...field} value={separateThousand(field.value ?? '')} onChange={e => field.onChange(separateThousand(e.target.value))} errorMessage={error?.message} disabled={updateEventMutation.isPending || eventQuery.isLoading} />} />
            </div>
          </div>}
      </form>
    </ModalNew>;
};
export default EditEventTitleModal;