'use client';

import React from 'react';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/atom/dialog';
import { Button } from '@/components/atom/button';
import { ChevronLeft } from 'lucide-react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { cn } from '@/lib/utils';
import CreateShopItemForm from './CreateShopItemForm';
import useShopStore from '@/hooks/store/use-shop-store';
const CreateShopItemModal = () => {
  const {
    shopSuccess
  } = useShopStore();
  const [step, setStep] = React.useState(1);
  const params = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();
  return <Dialog open={params.get('type') === 'store-item' && !shopSuccess} onOpenChange={() => router.replace(pathname)} data-sentry-element="Dialog" data-sentry-component="CreateShopItemModal" data-sentry-source-file="CreateShopItemModal.tsx">
      <DialogContent className="w-screen h-screen md:h-[80vh] p-4 md:px-10 md:pb-10 antialiased flex flex-col items-center md:w-full max-w-[440px] rounded-none md:rounded-xl md:max-h-[640px] lg:max-h-[640px]" data-sentry-element="DialogContent" data-sentry-source-file="CreateShopItemModal.tsx">
        <DialogHeader className="w-full mx-auto text-start py-10" data-sentry-element="DialogHeader" data-sentry-source-file="CreateShopItemModal.tsx">
          <DialogTitle className="relative font-medium text-2xl text-purple-brand3 flex items-center mb-4" data-sentry-element="DialogTitle" data-sentry-source-file="CreateShopItemModal.tsx">
            <Button variant="ghost" size="icon" className="rounded-full h-fit w-fit md:hidden" onClick={() => router.replace(pathname)} data-sentry-element="Button" data-sentry-source-file="CreateShopItemModal.tsx">
              <ChevronLeft size={16} data-sentry-element="ChevronLeft" data-sentry-source-file="CreateShopItemModal.tsx" />
            </Button>
            <span className="sr-only">Add a store item</span>
            <span className="block md:hidden text-sm ml-4">Go back</span>
            <span className="hidden md:block">Add a store item</span>
          </DialogTitle>

          <DialogDescription className="grid grid-cols-3 gap-2 items-center justify-center" data-sentry-element="DialogDescription" data-sentry-source-file="CreateShopItemModal.tsx">
            <div className={cn('h-1 w-full rounded-md bg-purple', step === 1 && 'shadow-icon bg-pink-brand2')}></div>
            <div className={cn('h-1 w-full rounded-md bg-gray-brand3', step === 3 && 'bg-purple', step === 2 && 'shadow-icon bg-pink-brand2')}></div>
            <div className={cn('h-1 w-full bg-gray-brand3 rounded-md', step === 3 && 'shadow-icon bg-pink-brand2')}></div>
          </DialogDescription>
        </DialogHeader>

        <div className="w-full h-fit pb-6">
          <CreateShopItemForm step={step} setStep={setStep} data-sentry-element="CreateShopItemForm" data-sentry-source-file="CreateShopItemModal.tsx" />
        </div>

        {/* <div className="flex items-center justify-center w-full h-full flex-1 flex-grow border border-red-600">
          <CreateShopItemForm step={step} setStep={setStep} />
         </div> */}
      </DialogContent>
    </Dialog>;
};
export default CreateShopItemModal;