'use client';

import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/atom/dropdown-menu';
import { Button } from '@/components/atom/button';
import { BellIcon, MenuIcon, SearchIcon } from '@/components/icons';
import { ChevronDown, X } from 'lucide-react';
import { PAGE_SEARCH_PARAMS, PRIVATE_NAVBAR_LIST, USER_NAVBAR_LIST, keyboardKeys } from '@/constants';
import { SignOut } from '@/server/actions/auth.actions';
import Avatar from '@/components/atom/avatar';
import { Drawer, DrawerClose, DrawerContent, DrawerHeader, DrawerTitle, DrawerTrigger } from '@/components/atom/drawer';
import Sidebar from './Sidebar';
import { User } from 'next-auth';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/atom/button';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useQueryClient } from '@tanstack/react-query';
import { useMixpanel } from '@/providers/MixPanelProvider';
import { signOut } from 'next-auth/react';
import { routePaths } from '@/routes/routePaths';
import MinifiedNotificationList from './notifications/MinifiedNotificationList';
import useGetUserNotifications from '@/hooks/react-query/notifications/queries/useGetUserNotifications';
type SearchBarprops = {
  handleSearch: () => void;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleCancelSearch: (event?: React.KeyboardEvent<HTMLInputElement>) => void;
  inputClassName: string;
};
const SearchBar = ({
  handleCancelSearch,
  handleKeyDown,
  handleSearch,
  searchValue,
  setSearchValue,
  inputClassName
}: SearchBarprops) => {
  return <div className="relative md:w-[324px] w-full transition duration-500 ease-in-out h-full" data-sentry-component="SearchBar" data-sentry-source-file="PrivateNavbar.tsx">
      <button type="button" disabled={searchValue.length < 1} className="absolute inset-y-0 start-0 z-10 flex items-center ms-2.5 hover:opacity-80  rounded-full  justify-center disabled:cursor-not-allowed" onClick={handleSearch}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="PrivateNavbar.tsx">
          <path d="M9.58268 17.5003C13.9549 17.5003 17.4993 13.9559 17.4993 9.58366C17.4993 5.2114 13.9549 1.66699 9.58268 1.66699C5.21043 1.66699 1.66602 5.2114 1.66602 9.58366C1.66602 13.9559 5.21043 17.5003 9.58268 17.5003Z" stroke="#7F7186" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="PrivateNavbar.tsx" />
          <path d="M18.3327 18.3337L16.666 16.667" stroke="#7F7186" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="PrivateNavbar.tsx" />
        </svg>
      </button>
      <input type="text" className={cn('flex w-full duration-500 ease-in-out rounded-sm border border-gray-brand2 bg-white px-3 py-1 text-sm transition text-black-brand file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-brand9 focus-visible:outline-none focus-visible:ring-0 focus-visible:border-purple-brand disabled disabled:bg-gray-brand4 ps-10', searchValue.length > 0 && 'pe-10', inputClassName)} placeholder="Search" value={searchValue} onChange={e => {
      setSearchValue(e.target.value);
    }} onKeyDown={handleKeyDown} />
      <button className="absolute h-4 w-4 inset-y-0 top-2.5 end-0 me-2.5 bg-none rounded-full flex items-center justify-center disabled:cursor-not-allowed"
    // disabled={searchValue.length < 1}
    onClick={() => {
      handleCancelSearch();
    }}>
        <X size={20} className="text-[#7F7186]" data-sentry-element="X" data-sentry-source-file="PrivateNavbar.tsx" />
      </button>
    </div>;
};
const PrivateNavbar = ({
  user
}: {
  user?: User;
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const {
    trackEvent
  } = useMixpanel();
  const queryClient = useQueryClient();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const searchQuery = searchParams.get(PAGE_SEARCH_PARAMS.searchQuery) || '';
  const [searchValue, setSearchValue] = React.useState('');
  const [searchIsOpen, setSearchIsOpen] = React.useState(searchQuery ? true : false);
  const {
    isLoading,
    hasUnreadNotification
  } = useGetUserNotifications({
    UserId: user?.userId,
    PageSize: 5
  });
  React.useEffect(() => {
    if (searchQuery.length > 0) {
      setSearchValue(searchQuery);
      setSearchIsOpen(true);
    } else {
      setSearchValue('');
      setSearchIsOpen(false);
    }
  }, [searchQuery]);
  const handleSearch = () => {
    if (searchValue.trim()) {
      if (pathname === routePaths.search && searchParams.has(PAGE_SEARCH_PARAMS.tab)) {
        const updatedSearchParams = new URLSearchParams(searchParams.toString());
        updatedSearchParams.set(PAGE_SEARCH_PARAMS.searchQuery, searchValue);
        router.push(`${pathname}?${updatedSearchParams.toString()}`);
      } else {
        router.push(`${routePaths.search}?${PAGE_SEARCH_PARAMS.searchQuery}=${searchValue}`);
      }
    }
  };
  const handleCancelSearch = (event?: React.KeyboardEvent<HTMLInputElement>) => {
    setSearchValue('');
    event?.currentTarget.blur();
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === keyboardKeys.enter) {
      handleSearch();
      return;
    }
    if (event.key === keyboardKeys.escape) {
      handleCancelSearch(event);
      return;
    }
  };
  return <>
      <nav className="w-full fixed left-0 right-0 top-0 flex shadow-header bg-white z-50 h-14 md:h-[68px] text-sm">
        <div className="flex items-center h-full justify-between w-full max-w-[1280px] mx-auto px-4 lg:px-10">
          <div className="flex items-center gap-10">
            <div className="flex items-center gap-2 md:hidden">
              <Drawer open={open} onOpenChange={() => setOpen(!open)} data-sentry-element="Drawer" data-sentry-source-file="PrivateNavbar.tsx">
                <DrawerTrigger asChild data-sentry-element="DrawerTrigger" data-sentry-source-file="PrivateNavbar.tsx">
                  <Button className="h-6 w-6 flex bg-transparent" variant="ghost" size="icon" data-sentry-element="Button" data-sentry-source-file="PrivateNavbar.tsx">
                    <MenuIcon data-sentry-element="MenuIcon" data-sentry-source-file="PrivateNavbar.tsx" />
                  </Button>
                </DrawerTrigger>
                <DrawerContent hideClose side="left" className="p-0 py-4 w-fit justify-start md:hidden" overlayClassName="md:hidden" data-sentry-element="DrawerContent" data-sentry-source-file="PrivateNavbar.tsx">
                  <DrawerHeader data-sentry-element="DrawerHeader" data-sentry-source-file="PrivateNavbar.tsx">
                    <DrawerTitle className="text-sm text-gray-brand7 space-x-2 flex items-center justify-start" data-sentry-element="DrawerTitle" data-sentry-source-file="PrivateNavbar.tsx">
                      <DrawerClose asChild data-sentry-element="DrawerClose" data-sentry-source-file="PrivateNavbar.tsx">
                        <Button variant="ghost" size="icon" data-sentry-element="Button" data-sentry-source-file="PrivateNavbar.tsx">
                          <X size={20} data-sentry-element="X" data-sentry-source-file="PrivateNavbar.tsx" />
                        </Button>
                      </DrawerClose>
                      MENU
                    </DrawerTitle>
                  </DrawerHeader>

                  <Sidebar isAuthenticated={!!user} asChild hideCreate onItemClick={() => setOpen(false)} data-sentry-element="Sidebar" data-sentry-source-file="PrivateNavbar.tsx" />

                  <div className="flex flex-col items-center flex-1 flex-grow gap-6">
                    {PRIVATE_NAVBAR_LIST.map(item => item.external ? <a key={item.name} href={item.path} target="_blank">
                          {item.name}
                        </a> : <Link onClick={() => trackEvent('navigate_click', {
                    name: `Clicked navigation item ${item.name} to navigate to ${item.path}`
                  })} key={item.name} href={item.path}>
                          {item.name}
                        </Link>)}
                  </div>
                </DrawerContent>
              </Drawer>

              <Link href="/" data-sentry-element="Link" data-sentry-source-file="PrivateNavbar.tsx">
                <Image src="/favicon/android-chrome-192x192.png" width={24} height={24} alt="Fusion" priority data-sentry-element="Image" data-sentry-source-file="PrivateNavbar.tsx" />
              </Link>
            </div>

            <Link href="/" className="hidden md:block" data-sentry-element="Link" data-sentry-source-file="PrivateNavbar.tsx">
              <Image src="/assets/fusion/Logocolored.svg" width={78.55} height={20} alt="Fusion" priority data-sentry-element="Image" data-sentry-source-file="PrivateNavbar.tsx" />
            </Link>

            <div className="hidden md:flex items-center flex-1 flex-grow gap-6">
              {PRIVATE_NAVBAR_LIST.map(item => item.external ? <a key={item.name} href={item.path} target="_blank">
                    {item.name}
                  </a> : <Link key={item.name} href={item.path}>
                    {item.name}
                  </Link>)}
            </div>
          </div>
          <div className="flex flex-grow flex-1 items-center justify-end gap-2 md:gap-3">
            {!searchIsOpen ? <Button variant="ghost" size="icon" className="hover:cursor-pointer" onClick={() => setSearchIsOpen(true)}>
                <SearchIcon />
              </Button> : <SearchBar handleCancelSearch={handleCancelSearch} handleKeyDown={handleKeyDown} handleSearch={handleSearch} searchValue={searchValue} setSearchValue={setSearchValue} inputClassName={`h-9`}
          // height={full}
          />}

            {user && <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="icon" className="hover:cursor-pointer relative">
                    <BellIcon />
                    {!isLoading && hasUnreadNotification && <div className="absolute right-2 top-2 w-2 h-2 rounded-full bg-orange-brand2"></div>}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="center" className="w-[calc(100vw-40px)] md:w-[384px] p-0">
                  <MinifiedNotificationList user={user} />
                </DropdownMenuContent>
              </DropdownMenu>}

            {user ? <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button className="flex w-fit gap-2.5 font-semibold" variant="ghost" size="icon">
                    <Avatar fallbackText={user?.name ?? ''} className="h-8 w-8" src={(user?.avatarUrl || user?.image) ?? ''} isOnline />
                    <span className="md:flex items-center justify-between gap-2.5 hidden">
                      {user?.name} <ChevronDown className="ml-auto" size={20} />
                    </span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="min-w-[200px] p-4 space-y-3 pb-3">
                  <DropdownMenuItem className="h-9 w-full gap-2.5 ">
                    <Avatar fallbackText={user?.name ?? ''} className="h-10 w-10" src={user?.avatarUrl ?? ''} isOnline />
                    <div className="flex flex-col">
                      <p className="font-semibold">{user?.name}</p>
                      <p className="text-xs">{user?.username}</p>
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  {USER_NAVBAR_LIST.map(item => <DropdownMenuItem key={item.name} className="h-9 w-full ">
                      {item.external ? <a href={item.path} target="_blank">
                          {item.name}
                        </a> : <Link href={item.path}>{item.name}</Link>}
                    </DropdownMenuItem>)}
                  <DropdownMenuSeparator />

                  <DropdownMenuItem>
                    <button onClick={async () => {
                  setLoading(true);
                  try {
                    await SignOut();
                    await signOut();
                    queryClient.cancelQueries();
                    queryClient.clear();
                  } catch (error) {} finally {
                    setLoading(false);
                  }
                }} className="text-purple-brand font-semibold text-sm">
                      Log Out
                    </button>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu> : <>
                <Link href={`${pathname}?ref=signin`} className={cn(buttonVariants({
              variant: 'ghost',
              size: 'sm'
            }))}>
                  Log In
                </Link>
                <Link href="/signup" className={cn(buttonVariants({
              size: 'sm'
            }))}>
                  Sign Up
                </Link>
              </>}
          </div>

          {/* MOB1LE SEARCH PANEL */}
          {searchIsOpen && <div className="md:hidden absolute left-0 top-0 px-4 py-2.5 w-full h-full transition duration-500 ease-in-out bg-white z-[100]">
              <SearchBar handleCancelSearch={handleCancelSearch} handleKeyDown={handleKeyDown} handleSearch={handleSearch} searchValue={searchValue} setSearchValue={setSearchValue} inputClassName={`h-full`} />
            </div>}
        </div>
      </nav>

      {loading && <div className="fixed top-0 bottom-0 left-0 right-0 h-screen w-screen flex items-center justify-center bg-pink-brand-muted/40 z-20">
          Signing out...
        </div>}
    </>;
};
export default PrivateNavbar;