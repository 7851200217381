import { cn, parseMentions } from '@/lib/utils';
import Link from 'next/link';
import React from 'react';
const MentionText: React.FC<{
  text: string;
  className?: string;
}> = ({
  text,
  className
}) => {
  const parsedText = parseMentions(text);
  return <div className={cn('mb-2 text-xs text-gray-brand7 whitespace-pre-line break-words prose', className)} data-sentry-component="MentionText" data-sentry-source-file="MentionText.tsx">
      {parsedText.map((part, index) => typeof part === 'string' ?
    // Render normal text
    <React.Fragment key={index}>{part}</React.Fragment> :
    // Render mention
    <Link href={`/profile/${part.id}`} key={index} className="text-orange-brand2 cursor-pointer hover:underline text-sm">
            @{part.name}
          </Link>)}
    </div>;
};
export default MentionText;