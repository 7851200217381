'use client';

import React from 'react';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN } from '@/configs/global-variables';
import { useSession } from 'next-auth/react';
import { usePathname } from 'next/navigation';
interface MixPanelItemType {
  name: string;
  slug: string;
  description: string;
  createdAt: Date;
}
interface MixpanelContextType {
  trackEvent: (eventName: string, properties?: Record<string, any>) => void;
  identifyUser: (userId: string) => void;
  setUserProperties: (properties: Record<string, any>) => void;
  trackViewCommunity: (v: MixPanelItemType) => void;
  trackViewEvent: (v: MixPanelItemType) => void;
  trackViewPost: (v: MixPanelItemType) => void;
}
const generateAnonymousUserId = () => {
  // Generate a random unique identifier
  return `anon-${crypto.randomUUID()}`;
};
const MixpanelContext = React.createContext<MixpanelContextType | undefined>(undefined);
export const MixpanelProvider: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const pathname = usePathname();
  const session = useSession();
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [userId, setUserId] = React.useState<string | null>(null);
  React.useEffect(() => {
    if (MIXPANEL_TOKEN && !isInitialized) {
      mixpanel.init(MIXPANEL_TOKEN, {
        debug: process.env.NODE_ENV !== 'production'
      });
      setIsInitialized(true);
    }

    // Check if userId exists in local storage; otherwise, generate a new one
    let storedUserId = localStorage.getItem('mixpanel_anonymous_user_id');
    if (!storedUserId) {
      storedUserId = generateAnonymousUserId();
      localStorage.setItem('mixpanel_anonymous_user_id', storedUserId);
    }
    setUserId(session.data?.user?.id ?? storedUserId);
    if (isInitialized && storedUserId) {
      mixpanel.identify(storedUserId); // Identify the anonymous user
    }
  }, [isInitialized, session.data?.user?.id]);
  React.useEffect(() => {
    if (isInitialized && pathname) {
      mixpanel.track('Page Viewed', {
        path: pathname
      });
    }
  }, [pathname, isInitialized]);
  const trackEvent = (eventName: string, properties?: Record<string, any>) => {
    if (isInitialized) {
      identifyUser();
      mixpanel.track(eventName, properties);
    }
  };
  const identifyUser = () => {
    if (isInitialized && userId) {
      mixpanel.identify(userId);
    }
  };
  const setUserProperties = (properties: Record<string, any>) => {
    if (isInitialized) {
      mixpanel.people.set(properties);
    }
  };
  const trackViewCommunity = (community: MixPanelItemType) => {
    trackEvent('community_viewed', {
      ...community
    });
  };
  const trackViewPost = (community: MixPanelItemType) => {
    trackEvent('post_detail_viewed', {
      ...community
    });
  };
  const trackViewEvent = (community: MixPanelItemType) => {
    trackEvent('event_detail_viewed', {
      ...community
    });
  };
  return <MixpanelContext.Provider value={{
    trackEvent,
    identifyUser,
    setUserProperties,
    trackViewCommunity,
    trackViewEvent,
    trackViewPost
  }} data-sentry-element="unknown" data-sentry-component="MixpanelProvider" data-sentry-source-file="MixPanelProvider.tsx">
      {children}
    </MixpanelContext.Provider>;
};
export const useMixpanel = () => {
  const context = React.useContext(MixpanelContext);
  if (!context) {
    throw new Error('useMixpanel must be used within a MixpanelProvider');
  }
  return context;
};