import React from 'react';

const useVideoThumbnail = ({ videoUrl }: { videoUrl?: string }) => {
  const [thumbnailUrl, setThumbnailUrl] = React.useState<string | null>(null);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  React.useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (!video || !canvas) return;

    const generateThumbnail = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext('2d')?.drawImage(video, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(
        (blob) => {
          if (blob) {
            const url = URL.createObjectURL(blob);
            setThumbnailUrl(url);
          }
        },
        'image/jpeg',
        0.7
      );
    };

    video.onloadeddata = () => {
      video.currentTime = 1; // Seek to 1 second
    };

    video.onseeked = generateThumbnail;

    return () => {
      if (thumbnailUrl) {
        URL.revokeObjectURL(thumbnailUrl);
      }
    };
  }, [videoUrl]);

  const generateThumbnailFromFile = async (
    videoFile: File,
    timestamp: number = 1 // Timestamp in seconds to capture the thumbnail
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      // Create a video element
      const video = document.createElement('video');
      video.src = URL.createObjectURL(videoFile); // Use a blob URL for the video file
      video.crossOrigin = 'anonymous'; // Handle cross-origin if necessary

      // Handle video loading errors
      video.onerror = () => reject(new Error('Failed to load video file'));

      // Wait for the video metadata to load
      video.onloadedmetadata = () => {
        if (timestamp > video.duration) {
          reject(new Error('Timestamp exceeds video duration'));
          return;
        }

        // Seek to the specified timestamp
        video.currentTime = timestamp;
      };

      // When the video seeks to the desired frame
      video.onseeked = () => {
        try {
          // Create a canvas element
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          // Draw the current video frame onto the canvas
          const context = canvas.getContext('2d');
          if (!context) throw new Error('Failed to get canvas context');
          context.drawImage(video, 0, 0, canvas.width, canvas.height);

          // Convert the canvas to a Base64 string
          const base64Image = canvas.toDataURL('image/jpeg', 0.8); // Output as JPEG, quality 0.8
          resolve(base64Image);
        } catch (error) {
          reject(error);
        }
      };
    });
  };

  const generateThumbnailsFromFiles = async (
    videoFiles: File[],
    timestamp: number = 1 // Timestamp in seconds to capture the thumbnail
  ): Promise<string[]> => {
    const generateThumbnailFromFile = (videoFile: File): Promise<string> => {
      return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.src = URL.createObjectURL(videoFile);
        video.crossOrigin = 'anonymous';

        video.onerror = () => reject(new Error(`Failed to load video file: ${videoFile.name}`));

        video.onloadedmetadata = () => {
          if (timestamp > video.duration) {
            reject(new Error(`Timestamp exceeds video duration for file: ${videoFile.name}`));
            return;
          }
          video.currentTime = timestamp;
        };

        video.onseeked = () => {
          try {
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            const context = canvas.getContext('2d');
            if (!context) throw new Error('Failed to get canvas context');
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            const base64Image = canvas.toDataURL('image/jpeg', 0.8);
            resolve(base64Image);
          } catch (error) {
            reject(error);
          }
        };
      });
    };

    // Generate thumbnails for all video files
    return Promise.all(videoFiles.map(generateThumbnailFromFile));
  };

  return {
    thumbnailUrl,
    videoRef,
    canvasRef,
    generateThumbnailFromFile,
    generateThumbnailsFromFiles,
  };
};

export default useVideoThumbnail;
