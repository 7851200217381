import { useInfiniteQuery } from '@tanstack/react-query';
import { axiosClientInstance } from '@/configs/axiosClientInstance';
import { NOTIFICATIONS_ENDPOINTS } from '@/constants/apiPath';
import { FUSION_API } from '@/configs/global-variables';

export type NotificationsParamsType = {
  UserId?: number;
  CategoryId?: number;
  IsRead?: boolean;
  IsUnread?: boolean;
  Archive?: boolean;
  PageIndex?: number;
  PageSize?: number;
};

export async function getNotifications(params: NotificationsParamsType) {
  try {
    const res = await axiosClientInstance.get(NOTIFICATIONS_ENDPOINTS.getAll, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.apiMicroServiceNotifications,
    });
    return res?.data;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
}

const useGetUserNotifications = ({
  PageSize = 10,
  PageIndex,
  ...rest
}: NotificationsParamsType) => {
  const { data, ...other } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ['get-all-notifications', { PageSize, PageIndex, ...rest }],
    queryFn: ({ pageParam }) => getNotifications({ PageSize, PageIndex: pageParam, ...rest }),
    getNextPageParam: (lastPage: any, pages) =>
      lastPage?.hasNextPage ? pages.length + 1 : undefined,
  });

  const payload = data?.pages.reduce((acc, page) => {
    return [...acc, ...page.data];
  }, []);

  const hasUnreadNotification = data?.pages[0]?.hasUnreadNotification ?? false;

  return {
    ...other,
    data: payload,
    total: data?.pages[0]?.totalCount ?? '0',
    hasUnreadNotification,
  };
};

export default useGetUserNotifications;
