'use client';

import React, { useState, useRef } from 'react';
import { Trash2 } from 'lucide-react';
import { Button } from '@/components/atom/button';
import { ImageGalleryAdd } from '@/components/icons';
import { MAX_IMAGE_IN_BYTE, MAX_IMAGE_IN_MEGABYTE } from '@/configs/global-variables';
import { useToast } from '@/hooks/use-toast';
import PictureTaker from '@/components/PictureTaker';
import Img from '@/components/atom/img';
import { imageUrlToBase64 } from '@/lib/url-to-base64';
import base64ToFile from '@/lib/base64-to-file';
export default function CommunityImageInput({
  onImageCaptured,
  disabled,
  accept,
  defaultValue
}: {
  onImageCaptured?: (v: string, file?: File | null) => void;
  disabled?: boolean;
  accept?: string;
  defaultValue?: string;
}) {
  const {
    toast
  } = useToast();
  const [capturedImage, setCapturedImage] = useState('');
  const [file, setFile] = useState<File | undefined>(undefined);
  const videoRef = useRef<HTMLVideoElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.size <= MAX_IMAGE_IN_BYTE) {
      const reader = new FileReader();
      reader.onload = e => setCapturedImage((e.target?.result as string));
      reader.readAsDataURL(file);
    } else {
      toast({
        variant: 'destructive',
        description: `Image size exceeds the maximum allowed size of ${MAX_IMAGE_IN_MEGABYTE}MB`
      });
    }
  };
  React.useEffect(() => {
    onImageCaptured?.(capturedImage, fileInputRef?.current?.files?.[0] ?? file);

    // return () => {
    //   setFile(undefined);
    // };
  }, [capturedImage, file]);
  React.useEffect(() => {
    if (defaultValue) {
      imageUrlToBase64(defaultValue).then(url => {
        setCapturedImage(url);
        const file = base64ToFile((url as string), `${new Date().getTime()}`);
        setFile(file);
      });
    }
    // onImageCaptured?.(capturedImage, fileInputRef?.current?.files?.[0] ?? file);

    // // return () => {
    // //   setFile(undefined);
    // // };
  }, [defaultValue]);
  return <div className="flex flex-col items-center" data-sentry-component="CommunityImageInput" data-sentry-source-file="CommunityImageInput.tsx">
      <div className="w-full flex items-center justify-between gap-4">
        <div className="relative">
          <div className="h-32 w-32 min-w-32 bg-gray-brand4 rounded-full overflow-hidden shadow-img">
            {capturedImage ? <Img src={capturedImage} alt="Captured" className="w-full h-full object-cover" /> : <div className="w-full h-full bg-gray-brand4 flex items-center justify-center">
                <ImageGalleryAdd />
              </div>}
          </div>
          {capturedImage && <button type="button" disabled={disabled} onClick={() => setCapturedImage('')} className="absolute bottom-0 right-0 p-2 bg-white rounded-full shadow-md">
              <Trash2 className="w-6 h-6 text-gray-600" />
            </button>}
        </div>
        <div className="space-y-4">
          <Button disabled={disabled} onClick={() => fileInputRef.current?.click()} className="w-full text-purple-brand shadow-xs" variant="outline" type="button" data-sentry-element="Button" data-sentry-source-file="CommunityImageInput.tsx">
            Browse Gallery
          </Button>
          <input disabled={disabled} type="file" ref={fileInputRef} onChange={handleFileInput}
        // accept=".jpeg,.jpg,.png,.mp4,.avi,.mov,.gif,.3pp,.webm,.flv,.mpeg,.m4v"
        accept={accept} className="hidden" />
          <PictureTaker onCaptureImage={(imageSrc, file) => {
          setFile(file);
          setCapturedImage((imageSrc as string));
        }} type="landscape" data-sentry-element="PictureTaker" data-sentry-source-file="CommunityImageInput.tsx" />
        </div>
      </div>
      {!capturedImage && <video ref={videoRef} autoPlay playsInline className="mt-4 w-full max-w-md rounded-lg hidden" />}
    </div>;
}