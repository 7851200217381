'use client';

import { MICROSOFT_CLARITY } from '@/configs/global-variables';
import Script from 'next/script';
const MicrosoftClarity = () => {
  return <Script id="microsoft-clarity-init" strategy="afterInteractive" dangerouslySetInnerHTML={{
    __html: `
                (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "${MICROSOFT_CLARITY}");
                `
  }} data-sentry-element="Script" data-sentry-component="MicrosoftClarity" data-sentry-source-file="MicrosoftClarity.tsx" />;
};
export default MicrosoftClarity;