import React from 'react';
import { RouterLink } from 'src/routes/components';
import Link from 'next/link';
import Img from './atom/img';
type LogoProps = (React.HTMLAttributes<HTMLDivElement> | React.HTMLAttributes<HTMLAnchorElement>) & {
  disabledLink?: boolean;
  sx: any;
};

// ----------------------------------------------------------------------

const Logo = React.forwardRef<HTMLDivElement | HTMLAnchorElement, LogoProps>(({
  disabledLink = false,
  sx
}, ref) => {
  // OR using local (public folder)
  // -------------------------------------------------------
  const logo = <Img src="/assets/fusion/Logocolored.svg" className="w-[60px] h-[50px] cursor-pointer" alt="fusion-logo" fill />;
  if (disabledLink) {
    return logo;
  }
  return (
    // @ts-ignore
    <Link ref={ref} component={RouterLink} href="/" className="contents">
        {logo}
      </Link>
  );
});
Logo.displayName = 'Logo';
export default Logo;