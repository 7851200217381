'use client';

import { GOOGLE_ANALYTICS } from '@/configs/global-variables';
import Script from 'next/script';
const GoogleAnalytics = () => {
  return <>
      <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS}`} data-sentry-element="Script" data-sentry-source-file="GoogleAnalytics.tsx" />
      <Script id="google-analytics-init" strategy="afterInteractive" dangerouslySetInnerHTML={{
      __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', '${GOOGLE_ANALYTICS}');
                    `
    }} data-sentry-element="Script" data-sentry-source-file="GoogleAnalytics.tsx" />
    </>;
};
export default GoogleAnalytics;