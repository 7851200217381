import { axiosClientInstance } from '@/configs/axiosClientInstance';
import { FUSION_API } from '@/configs/global-variables';
import { COMMENTS_ENDPOINTS, COMMUNITIES_ENDPOINTS } from '@/constants/apiPath';
import {
  CommentsParams,
  CreateCommentPayloadType,
  CreatePostPayloadType,
  JoinPayload,
  PostParamsType,
  PostType,
  ToggleInputType,
} from '@/types';
import handleError, { ErrorPayload } from '@/utils/handleError';
import { AxiosError } from 'axios';
import { cache } from 'react';

export const fetchSinglePost = cache(async ({ slug }: { slug?: string }) => {
  try {
    const res = await axiosClientInstance.get('/Post/GetBySlug', {
      baseURL: FUSION_API.communitiesApi,
      params: {
        slug: slug,
      },
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
});

export const fetchComments = cache(async ({ postId, parentType }: CommentsParams) => {
  try {
    const res = await axiosClientInstance.get('/Comment/GetByContentId', {
      baseURL: FUSION_API.communitiesApi,
      params: {
        contentId: postId,
        ParentType: parentType,
      },
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(
      error as AxiosError<ErrorPayload>,
      'Something went wrong while performing action'
    );
  }
});

export const toggleLike = async ({ contentId, parentType, like }: ToggleInputType) => {
  try {
    const res = await axiosClientInstance.post(
      '/Engagement/LikeOrUnlike',
      { contentId, parentType, like },
      {
        baseURL: FUSION_API.communitiesApi,
      }
    );
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>, 'Something went wrong');
  }
};

export const createPost = async (payload: CreatePostPayloadType) => {
  try {
    const res = await axiosClientInstance.post('/Post/create-post', payload, {
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(
      error as AxiosError<ErrorPayload>,
      'Something went wrong while performing action'
    );
  }
};
export const createPostComment = async (payload: CreateCommentPayloadType) => {
  try {
    const res = await axiosClientInstance.post(COMMENTS_ENDPOINTS.commentsCreate, payload, {
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(
      error as AxiosError<ErrorPayload>,
      'Something went wrong while performing action'
    );
  }
};

export const getPosts = cache(
  async ({ ...params }: PostParamsType & { popular?: boolean }): Promise<PostType[] | string> => {
    try {
      const res = await axiosClientInstance.get(COMMUNITIES_ENDPOINTS.getAllPosts, {
        params: {
          ...params,
        },
        baseURL: FUSION_API.communitiesApi,
      });
      return res.data?.data;
    } catch (error: unknown) {
      return handleError(error as AxiosError<ErrorPayload>, 'Something went wrong');
    }
  }
);

export const getUserFeedPosts = async function ({ ...params }: PostParamsType) {
  try {
    const res = await axiosClientInstance.get(COMMUNITIES_ENDPOINTS.getUserFeeds, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>, 'Something went wrong');
  }
};
export async function getExplorePosts({ ...params }: PostParamsType) {
  try {
    const res = await axiosClientInstance.get(COMMUNITIES_ENDPOINTS.getUserFeeds, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>, 'Something went wrong');
  }
}
export const getPostsByCommunity = async function ({ ...params }: PostParamsType) {
  try {
    const res = await axiosClientInstance.get(COMMUNITIES_ENDPOINTS.postsByCommunity, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>, 'Unable to fetch communities');
  }
};
export const joinOrLeaveCommunity = async function ({ isPrivate, ...payload }: JoinPayload) {
  try {
    const res = await axiosClientInstance.post(
      `${COMMUNITIES_ENDPOINTS.joinOrLeave}${isPrivate ? 'PrivateCommunity' : ''}`,
      payload,
      {
        baseURL: FUSION_API.communitiesApi,
      }
    );
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>, 'Unable to perform action');
  }
};
export async function getCommentsByPostId(params: PostParamsType) {
  try {
    const res = await axiosClientInstance.get(COMMENTS_ENDPOINTS.commentsById, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}
export async function getCommentsByPostSlug(params: PostParamsType) {
  try {
    const res = await axiosClientInstance.get(COMMENTS_ENDPOINTS.commentsBySlug, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}
