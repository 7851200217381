'use client';

import { useState, useEffect } from 'react';
import { CalendarIcon } from 'lucide-react';
import { format } from 'date-fns';
import { cn } from '@/lib/utils';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/atom/popover';
import { Button } from './button';
interface DatePickerProps {
  label: string;
  name: string;
  value?: Date;
  onChange?: (date: Date | undefined) => void;
  error?: string;
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
}
export default function DateField({
  label,
  name,
  value,
  onChange,
  error,
  required = false,
  minDate,
  maxDate
}: DatePickerProps) {
  const [date, setDate] = useState<Date | undefined>(value);
  const [internalError, setInternalError] = useState<string | null>(null);
  useEffect(() => {
    setDate(value);
  }, [value]);
  const handleDateChange = (newDate: Date | undefined) => {
    setDate(newDate);
    setInternalError(null);
    if (onChange) {
      onChange(newDate);
    }
  };
  const handleBlur = () => {
    if (required && !date) {
      setInternalError('Please select a date');
    } else {
      setInternalError(null);
    }
  };
  const displayError = error || internalError;
  return <div className="space-y-2" data-sentry-component="DateField" data-sentry-source-file="dateField.tsx">
      <label htmlFor={name}>{label}</label>
      <Popover modal data-sentry-element="Popover" data-sentry-source-file="dateField.tsx">
        <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="dateField.tsx">
          <Button variant="input" className={cn('w-full pl-3 text-left font-normal', !value && 'text-muted-foreground')} data-sentry-element="Button" data-sentry-source-file="dateField.tsx">
            {value ? format(value, 'PPP') : <span>Pick a date</span>}
            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" data-sentry-element="CalendarIcon" data-sentry-source-file="dateField.tsx" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-fit p-0 z-[1270]" data-sentry-element="PopoverContent" data-sentry-source-file="dateField.tsx">
          <Calendar mode="single" selected={date} onSelect={handleDateChange} fromDate={minDate} toDate={maxDate} data-sentry-element="Calendar" data-sentry-source-file="dateField.tsx" />
        </PopoverContent>
      </Popover>
      {displayError && <p className="text-sm text-red-500">{displayError}</p>}
      <input type="hidden" name={name} value={date ? format(date, 'yyyy-MM-dd') : ''} required={required} />
    </div>;
}