import React from 'react';
import { cn, parseMentions } from '@/lib/utils';
import Link from 'next/link';
export const PostContentText = ({
  text,
  className
}: {
  text: string | null;
  className?: string;
}) => {
  //No Text passed in
  if (!text) return <></>;
  const charLimit = 300;
  const [isExpanded, setIsExpanded] = React.useState(false);
  const hasMoreThanLimit = text.length > charLimit ? true : false;

  //if the text is not long enough to need a read more
  //Just render the text properly using the text parser function.
  if (!hasMoreThanLimit) {
    const parsedText = parseMentions(text);
    return <div className={cn('mb-2 text-sm text-gray-brand7 whitespace-pre-line break-words prose', className)}>
        {parsedText.map((part, index) => typeof part === 'string' ?
      // Render normal text
      <React.Fragment key={index}>{part}</React.Fragment> :
      // Render mention
      <Link href={`/profile/${part.id}`} key={index} className="text-orange-brand2 cursor-pointer hover:underline">
              @{part.name}
            </Link>)}
      </div>;
  }

  // if the text is long enough and not expeanded
  //then just show the shortened text by stripping the text and adding teh "..."
  if (hasMoreThanLimit && !isExpanded) {
    const shortenedText = `${text.slice(0, charLimit)}`;
    const parsedShortenedText = parseMentions(shortenedText);
    return <div className={cn('mb-2 text-sm text-gray-brand7 whitespace-pre-line break-words prose', className)}>
        {parsedShortenedText.map((part, index) => typeof part === 'string' ?
      // Render normal text
      <React.Fragment key={index}>{part}</React.Fragment> :
      // Render mention
      <Link href={`/profile/${part.id}`} key={index} className="text-orange-brand2 cursor-pointer hover:underline">
              @{part.name}
            </Link>)}

        <span>
          <button className="text-purple-brand outline-none font-semibold" onClick={() => setIsExpanded(true)}>
            ... Show More
          </button>
        </span>
      </div>;
  }

  //if the text is long enough and is expanded, then show the full thing using the parser function
  //and add the show more button at the end
  const parsedText = parseMentions(text);
  return <div className={cn('mb-2 text-sm text-gray-brand7 whitespace-pre-line break-words prose', className)} data-sentry-component="PostContentText" data-sentry-source-file="PostContentText.tsx">
      {parsedText.map((part, index) => typeof part === 'string' ?
    // Render normal text
    <React.Fragment key={index}>{part}</React.Fragment> :
    // Render mention
    <Link href={`/profile/${part.id}`} key={index} className="text-orange-brand2 cursor-pointer hover:underline">
            @{part.name}
          </Link>)}

      <button className="text-purple-brand outline-none font-semibold block" onClick={() => setIsExpanded(false)}>
        Show Less
      </button>
    </div>;
};