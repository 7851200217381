'use client';

import PropTypes from 'prop-types';
import { LazyMotion, m, domMax } from 'framer-motion';
import { ReactNode } from 'react';

// ----------------------------------------------------------------------

export function MotionLazy({
  children
}: {
  children: ReactNode;
}) {
  return <LazyMotion strict features={domMax} data-sentry-element="LazyMotion" data-sentry-component="MotionLazy" data-sentry-source-file="motion-lazy.tsx">
      <m.div style={{
      height: '100%'
    }} data-sentry-element="unknown" data-sentry-source-file="motion-lazy.tsx"> {children} </m.div>
    </LazyMotion>;
}
MotionLazy.propTypes = {
  children: PropTypes.node
};