import { TicketTypeOptions } from '@/constants';
import React from 'react';
import RadioButton from '@/components/atom/radio-button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/atom/select-input';
import { Controller, useFormContext } from 'react-hook-form';
import { ShopItemFormType } from './schemas';
import { Switch } from '@/components/atom/switch';
import { Info, Tag } from 'lucide-react';
import { Input } from '@/components/atom/input';
import { separateThousand } from '@/lib/separateThousand';
import AddressInput from '../ui/address-input';
import DateField from '../atom/dateField';
import { EventShopTypeEnum, TicketType } from '@/utils/enums';
import { Tooltip, TooltipContent, TooltipTrigger } from '../atom/tooltip';
import { Button } from '../atom/button';
import { generateTimeOptions } from '@/lib/utils';
const StepTwoForm = () => {
  const form = useFormContext<ShopItemFormType>();
  const ticketTypeWatcher = form.watch('ticketType');
  const eventTypeWatcher = form.watch('eventType');
  const multipleDayWatcher = form.watch('multiple');
  return <>
      {eventTypeWatcher === `${EventShopTypeEnum.Physical}` && <div className="space-y-4 w-full">
          <div className="w-full">
            <Controller name="venueAddress" control={form.control} render={({
          field,
          formState: {
            errors
          }
        }) => <AddressInput placeholder="Enter the address of the venue" label="Venue Address" value={(field.value as string)} onChange={field.onChange} errorMessage={errors?.venueAddress?.message} />} />
          </div>
        </div>}
      <div className="w-full">
        <label htmlFor="ticketType">Ticket type</label>
        <div className="grid grid-cols-2 gap-x-4">
          <Controller name="ticketType" control={form.control} render={({
          field,
          fieldState: {
            error
          }
        }) => <>
                {TicketTypeOptions.map(v => <RadioButton key={v.value} id={v.label} value={v.value} label={v.label} name={field.name} onChange={field.onChange} error={error?.message} checked={v.value.toString() === field.value} />)}

                {error?.message && <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
                    {error?.message}
                  </p>}
              </>} data-sentry-element="Controller" data-sentry-source-file="StepTwoForm.tsx" />
        </div>
      </div>

      {Number(ticketTypeWatcher) === TicketType.Paid && <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="price">Price</label>
            <div className="relative">
              <span className="absolute text-black-brand text-sm left-3 top-1/2 -translate-y-1/2 z-10">
                ₦
              </span>
              <Controller name="price" control={form.control} render={({
            field
          }) => <Input {...field} value={separateThousand(field.value ?? '')} onChange={e => field.onChange(separateThousand(e.target.value))} className="pl-7 pr-8" />} />
              <Tag className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400" size={18} />
            </div>
          </div>
          <div>
            <label htmlFor="numbersAvailable">Numbers available</label>
            <Controller name="numbersAvailable" control={form.control} render={({
          field,
          fieldState: {
            error
          }
        }) => <Input {...field} value={separateThousand(field.value ?? '')} onChange={e => field.onChange(separateThousand(e.target.value))} errorMessage={error?.message} />} />
          </div>
        </div>}

      <div className="w-full flex items-center justify-end gap-2 flex-row-reverse">
        <label htmlFor="multiple">Multiple days</label>
        <Controller name="multiple" control={form.control} render={({
        field
      }) => <Switch checked={field.value} onCheckedChange={field.onChange} aria-readonly />} data-sentry-element="Controller" data-sentry-source-file="StepTwoForm.tsx" />
      </div>

      {Boolean(multipleDayWatcher) ? <>
          <div className="w-full">
            <Controller name="eventDate" control={form.control} render={({
          field,
          fieldState: {
            error
          }
        }) => <DateField name={field.name} label="Start Date" value={field.value} onChange={field.onChange} error={error?.message ?? ''} minDate={new Date()} />} />
          </div>
          <div className="w-full">
            <Controller name="endDate" control={form.control} render={({
          field,
          fieldState: {
            error
          }
        }) => <DateField name={field.name} label="End Date" value={field.value || undefined} onChange={field.onChange} error={error?.message ?? ''} minDate={new Date()} />} />
          </div>
        </> : <div className="w-full">
          <Controller name="eventDate" control={form.control} render={({
        field,
        fieldState: {
          error
        }
      }) => <DateField name={field.name} label="Date" value={field.value} onChange={field.onChange} error={error?.message ?? ''} minDate={new Date()} />} />
        </div>}

      <div className="h-[1px] w-full bg-gray-brand10" />

      <div className="flex items-center gap-4 w-full">
        <div className="flex-grow">
          <label htmlFor="eventTime" className="text-base font-normal">
            Time
          </label>
          <Controller name="eventTime" control={form.control} render={({
          field,
          fieldState: {
            error
          }
        }) => <>
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger className="mt-1.5" hasError={!!error?.message}>
                    <SelectValue placeholder="_ _ : _ _    _ _ " />
                  </SelectTrigger>
                  <SelectContent className="h-[200px]">
                    {generateTimeOptions().map(time => <SelectItem key={time} value={time}>
                        {time}
                      </SelectItem>)}
                  </SelectContent>
                </Select>

                {error?.message && <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
                    {error?.message}
                  </p>}
              </>} data-sentry-element="Controller" data-sentry-source-file="StepTwoForm.tsx" />
        </div>
        <div className="flex-grow">
          <label htmlFor="duration" className="text-base font-normal flex items-center gap-0.5">
            Duration{' '}
            <Tooltip data-sentry-element="Tooltip" data-sentry-source-file="StepTwoForm.tsx">
              <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="StepTwoForm.tsx">
                <Button size="icon" variant="ghost" className="h-fit w-fit p-0.5" type="button" data-sentry-element="Button" data-sentry-source-file="StepTwoForm.tsx">
                  <Info size={14} className="text-yellow-brand" data-sentry-element="Info" data-sentry-source-file="StepTwoForm.tsx" />
                </Button>
              </TooltipTrigger>
              <TooltipContent data-sentry-element="TooltipContent" data-sentry-source-file="StepTwoForm.tsx">
                <p>
                  Specify the <b>hour</b>:<b>minutes</b>
                </p>
              </TooltipContent>
            </Tooltip>
          </label>
          <Controller name="duration" control={form.control} render={({
          field,
          fieldState: {
            error
          }
        }) => <>
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger className="mt-1.5" hasError={!!error?.message}>
                    <SelectValue placeholder="hh:mm" />
                  </SelectTrigger>
                  <SelectContent className="h-[200px]">
                    {Array.from({
                length: 24 * 4
              }, (_, i) => {
                const hours = Math.floor(i / 4).toString().padStart(2, '0');
                const minutes = ['00', '15', '30', '45'][i % 4];
                return <SelectItem key={i} value={`${hours}:${minutes}`}>
                          {`${hours}:${minutes}`}
                        </SelectItem>;
              })}
                  </SelectContent>
                </Select>
                {error?.message && <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
                    {error?.message}
                  </p>}
              </>} data-sentry-element="Controller" data-sentry-source-file="StepTwoForm.tsx" />
        </div>
      </div>
    </>;
};
export default StepTwoForm;