'use client';

import React from 'react';
import SignInModal from './auth/SigninModal';
import PreCreateModal from '@/molecules/PreCreate/PreCreateModal';
import CreatePostModal from '@/molecules/CreatePostModal';
import UpdatedCreatePostModal from '@/molecules/UpdatedCreatePostModal';
import CreateCommunityModal from '@/molecules/CreateCommunityModal';
import CreateCommunitySuccessModal from '@/molecules/CreateCommunitySuccessModal';
import ShareModal from '@/components/share/share-modal';
import CreateShopItemModal from '@/components/shop/CreateShopItemModal';
import { useSearchParams } from 'next/navigation';
import useCommunityStore from '@/hooks/store/use-community-store';
import useAppStore from '@/hooks/use-app-store';
import CreateShopItemSuccessModal from './shop/CreateShopItemSuccessModal';
import useShopStore from '@/hooks/store/use-shop-store';
import EditEventTitleModal from './shop/EditEventTitleModal';
import EditEventDetailsModal from './shop/EditEventDetailsModal';
import { MODAL_PARAMS } from '@/constants';
import useModalControl from '@/hooks/store/use-modal-control';
import JoinPrivateCommunity from './JoinPrivateCommunity';
const Modals = () => {
  const searchParams = useSearchParams();
  const {
    communitySuccess
  } = useCommunityStore();
  const {
    shopSuccess
  } = useShopStore();
  const {
    showShareModal
  } = useAppStore(state => state);
  const {
    modalType
  } = useModalControl();
  return <>
      {searchParams.get('ref') === 'signin' && <SignInModal />}
      {searchParams.get('ref') === 'create' && !searchParams.get('type') && <PreCreateModal />}
      {searchParams.get('type') === 'community' && !communitySuccess && <CreateCommunityModal />}
      {searchParams.get('type') === 'store-item' && !shopSuccess && <CreateShopItemModal />}
      {searchParams.get('type') === 'post' && <CreatePostModal />}
      {searchParams.get(MODAL_PARAMS.type) === MODAL_PARAMS.newPost && <UpdatedCreatePostModal />}
      {searchParams.get(MODAL_PARAMS.type) === MODAL_PARAMS.eventTitle && <EditEventTitleModal />}
      {searchParams.get(MODAL_PARAMS.type) === MODAL_PARAMS.eventDetails && <EditEventDetailsModal />}

      {/* state modals */}
      {communitySuccess && <CreateCommunitySuccessModal />}
      {shopSuccess && <CreateShopItemSuccessModal />}
      {showShareModal && <ShareModal />}
      {modalType.type === 'joinPrivateCommunity' && modalType.isOpen && <JoinPrivateCommunity />}
    </>;
};
export default Modals;