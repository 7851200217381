import { create } from 'zustand';

type ModalNames =
  | 'registrationSuccessful'
  | 'registrationOptions'
  | 'signIn'
  | 'cancelEvent'
  | 'controlTicketSales'
  | 'sendReminder'
  | 'postMediaView'
  | 'joinPrivateCommunity';

type ModalType = {
  type: ModalNames | null;
  isOpen: boolean;
  extraData?: any;
};

type ModalControlType = {
  modalType: ModalType;
  openModal: (type: ModalNames, extraData?: any) => void;
  closeModal: () => void;
};

const initialState: ModalType = { type: null, isOpen: false };

const useModalControl = create<ModalControlType>((set) => ({
  modalType: initialState,
  openModal: (type, extraData) =>
    set({
      modalType: { isOpen: true, type, extraData },
    }),
  closeModal: () =>
    set({
      modalType: { isOpen: false, type: null },
    }),
}));

export default useModalControl;
