import { cn } from "@/lib/utils";
import React from "react";
const CustomCarouselDot = ({
  onMove,
  index,
  onClick,
  active
}: any) => {
  return <li className="mx-1 transition duration-500 ease-in-out" data-sentry-component="CustomCarouselDot" data-sentry-source-file="custom-carousel-dot.tsx">
      <button aria-labelledby="carousel-button" onClick={() => !active && onClick()} className={cn("h-2 transition-all duration-500 ease-in-out", active ? "w-6 bg-purple rounded" : "rounded-full w-2 bg-gray-brand3")}></button>
    </li>;
};
export default CustomCarouselDot;