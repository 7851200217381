import React from "react";
import { cn } from "@/lib/utils";
import { Input } from "./input";
import { Eye, EyeOff } from "lucide-react";
import { CheckSuccess } from "@/components/icons";
import { passwordValidations } from "@/lib/schemas";
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  hideInnerChecks?: boolean;
  errorMessage?: string;
}
const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>(({
  className,
  type,
  hideInnerChecks,
  errorMessage,
  onChange,
  ...props
}, ref) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [value, setValue] = React.useState("");
  return <div className="space-y-2">
        <div className="relative">
          <button type="button" onClick={() => setShowPassword(!showPassword)} className="absolute right-0 top-2.5 z-10 flex items-center me-2.5 hover:opacity-80  rounded-full  justify-center">
            {showPassword ? <EyeOff className={cn("text-gray-brand5", errorMessage && "text-opal-brand")} size={14} /> : <Eye className={cn("text-gray-brand5", errorMessage && "text-opal-brand")} size={14} />}
          </button>
          <Input type={showPassword ? "text" : "password"} ref={ref} onChange={e => {
        setValue(e.target.value);
        onChange?.(e);
      }} errorMessage={errorMessage} hideErrorIcon {...props} />
        </div>

        <div className={cn((hideInnerChecks || !value) && "hidden")}>
          {passwordValidations.map((validation, index) => <div key={index} className="flex items-center gap-3 mb-2">
              <CheckSuccess checked={validation.test(value)} />

              <span className="text-gray-brand6 text-xs">
                {validation.label}
              </span>
            </div>)}
        </div>
      </div>;
});
PasswordInput.displayName = "PasswordInput";
export { PasswordInput };