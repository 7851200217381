import { updateCommunity } from '@/services/communities.service';
import { UpdateCommunityPayloadType } from '@/types';
import { useMutation } from '@tanstack/react-query';

const useUpdateCommunityMutation = () => {
  return useMutation({
    mutationKey: ['UpdateCommunity'],
    mutationFn: (payload: UpdateCommunityPayloadType) => updateCommunity(payload),
  });
};

export default useUpdateCommunityMutation;
