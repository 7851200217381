import React from 'react';
const Countdown = ({
  initialTime = 120,
  onTimeout,
  label = 'Resend OTP in'
}: {
  initialTime?: number;
  onTimeout?: () => void;
  label?: string;
}) => {
  const [timeLeft, setTimeLeft] = React.useState(initialTime);
  React.useEffect(() => {
    if (timeLeft === 0) return onTimeout?.();
    const intervalId = setInterval(() => {
      setTimeLeft(prev => prev - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft, onTimeout]);
  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };
  return <div className="flex items-center justify-center w-full text-sm" data-sentry-component="Countdown" data-sentry-source-file="Countdown.tsx">
      <p className="text-gray-brand7">
        {label}&nbsp; <span className="text-purple-brand font-medium">{formatTime(timeLeft)}</span>
        &nbsp; secs
      </p>
    </div>;
};
export default Countdown;