import { Button } from './atom/button';
import React from 'react';
import WebcamModal from './WebcamModal';
type PictureTakerProp = {
  onCaptureImage: (str: string | null | undefined, file?: File) => void;
  type: 'landscape' | 'portrait';
  onButtonClick?: () => void;
  disabled?: boolean;
};
const PictureTaker = ({
  onCaptureImage,
  type,
  onButtonClick,
  disabled
}: PictureTakerProp) => {
  const [open, setOpen] = React.useState(false);
  const [hasImage, setHasImage] = React.useState(false);
  return <>
      {open && <WebcamModal open={open} onCaptureImage={(v, w) => {
      onCaptureImage(v, w);
      setHasImage(!!v);
    }} type={type} onOpenChange={() => {
      setOpen(!open);
    }} />}
      <Button type="button" onClick={() => {
      setOpen(true);
      onButtonClick?.();
    }} variant="outline" className="w-full text-purple-brand bg-white shadow-xs" disabled={disabled} data-sentry-element="Button" data-sentry-source-file="PictureTaker.tsx">
        {hasImage ? 'Retake picture' : 'Take a picture'}
      </Button>
    </>;
};
export default PictureTaker;