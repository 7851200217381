import { cn } from '@/lib/utils';
import React from 'react';
export type RadioButtonType = React.InputHTMLAttributes<HTMLInputElement> & {
  label: React.ReactNode;
  labelClassName?: HTMLElement['className'];
  error?: string;
};
const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonType>(({
  label,
  labelClassName,
  className,
  id,
  value,
  error,
  ...rest
}, ref) => {
  return <div className="w-full fusion-radio">
        <input type="radio" id={id} value={value} ref={ref} className={cn('peer sr-only', className)} {...rest} />
        <label htmlFor={id} className={cn('inline-flex items-center w-full px-3 h-9 text-gray-brand5 border border-gray-brand2 rounded-md cursor-pointer peer-checked:border-[#B425F8] peer-checked:bg-pink-brand3 peer-checked:text-gray-brand7 hover:text-gray-brand6 hover:bg-neutral-50', !!error && 'border-opal-brand5 focus-visible:border-opal-brand5', labelClassName)}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" fill="white" />
            <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" stroke="#5B1179" className="hidden checked" />
            <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" stroke="#9B8FA1" className="unchecked" />
            <circle cx="9" cy="9" r="6" fill="#5B1179" className="hidden checked" />
          </svg>

          <div className="ml-4 text-sm leading-[22px]">{label}</div>
        </label>
      </div>;
});
RadioButton.displayName = 'RadioButton';
export default RadioButton;