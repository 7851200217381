'use client';

import React from 'react';
import { MotionLazy } from './animate/motion-lazy';
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
import { SessionProvider } from 'next-auth/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { TooltipProvider } from './atom/tooltip';
import { MixpanelProvider } from '@/providers/MixPanelProvider';
import { getQueryClient } from '@/app/getQueryClient';
import { FirebaseProvider } from '@/providers/FirebaseProvider';
const Providers = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const queryClient = getQueryClient();
  return <SessionProvider data-sentry-element="SessionProvider" data-sentry-component="Providers" data-sentry-source-file="providers.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="providers.tsx">
        <FirebaseProvider data-sentry-element="FirebaseProvider" data-sentry-source-file="providers.tsx">
          <MixpanelProvider data-sentry-element="MixpanelProvider" data-sentry-source-file="providers.tsx">
            <MotionLazy data-sentry-element="MotionLazy" data-sentry-source-file="providers.tsx">
              <ProgressBar height="4px" color="#7635dc" options={{
              showSpinner: false
            }} shallowRouting data-sentry-element="ProgressBar" data-sentry-source-file="providers.tsx" />
              <TooltipProvider data-sentry-element="TooltipProvider" data-sentry-source-file="providers.tsx">{children}</TooltipProvider>
            </MotionLazy>
          </MixpanelProvider>
        </FirebaseProvider>
      </QueryClientProvider>
    </SessionProvider>;
};
export default Providers;