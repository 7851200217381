'use client';

import React from 'react';
import ModalNew from '../atom/modal-new';
import { useRouter, useSearchParams, usePathname } from 'next/navigation';
import { MODAL_PARAMS, nigerianCountryCode, NigerianStates } from '@/constants';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '../atom/button';
import { EditEventDetailModel } from '@/lib/models';
import { editEventDetailSchema } from '@/lib/schemas';
import { Info, Loader2 } from 'lucide-react';
import { generateTimeOptions, transformStartAndEnd } from '@/lib/utils';
import useGetEventItemBySlug from '@/hooks/react-query/shop/queries/useGetEventItemBySlug';
import { UpdateEventDetailFormType } from '@/types';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '@/hooks/use-toast';
import AddressInput from '../ui/address-input';
import { SelectInput } from '../atom/SelectInput';
import { Switch } from '../atom/switch';
import DateField from '../atom/dateField';
import EditEventDetailLoading from './EditEventDetailLoading';
import { Tooltip, TooltipContent, TooltipTrigger } from '../atom/tooltip';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/atom/select-input';
import { calculateTimeRange, setTimeOnDateAndReturnISO } from '@/lib/format-dates';
import useUpdateEventDetailMutation from '@/hooks/react-query/shop/mutations/useUpdateEventDetailMutation';
import handleError, { ErrorPayload } from '@/utils/handleError';
import { AxiosError } from 'axios';
const EditEventDetailsModal = () => {
  const {
    toast
  } = useToast();
  const queryClient = useQueryClient();
  const router = useRouter();
  const params = useSearchParams();
  const pathname = usePathname();
  const eventId = params.get(MODAL_PARAMS.editEventId);
  const eventQuery = useGetEventItemBySlug({
    Slug: eventId ?? ''
  });
  const [previousValues, setPreviousValues] = React.useState<any>({});
  const form = useForm<EditEventDetailModel>({
    resolver: yupResolver(editEventDetailSchema)
  });
  const updateEventMutation = useUpdateEventDetailMutation();
  const onSubmit = () => {
    const formValues = form.watch();
    const range = calculateTimeRange(formValues?.eventTime?.replace(' ', ''), formValues?.duration);
    const endTime = range.split('-')[1].trim();
    const payload: UpdateEventDetailFormType = {
      eventId: eventQuery.data?.id,
      title: eventQuery.data?.title,
      description: eventQuery.data?.description,
      physicalLocation: {
        address: formValues.venueAddress,
        state: formValues.state,
        country: formValues.country
      },
      start: setTimeOnDateAndReturnISO(formValues.eventDate, formValues.eventTime?.replace(' ', '')),
      end: setTimeOnDateAndReturnISO(formValues.eventDate, endTime?.replace(' ', '')),
      multipleDays: Boolean(formValues?.multiple)
    };
    updateEventMutation.mutate(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['get-event-item-by-slug']
        });
        router.replace(pathname);
        toast({
          variant: 'success',
          title: 'Changes Saved',
          description: 'The changes you made to the event details have been saved.'
        });
      },
      onError: (e: unknown) => {
        const errorMsg = handleError((e as AxiosError<ErrorPayload>), 'The changes you made could not be saved.');
        toast({
          variant: 'destructive',
          title: 'Unable to save changes',
          description: errorMsg
        });
      }
    });
  };
  const areObjectsEqual = (obj1: any, obj2: any) => {
    return Object.keys(obj1).every(key => obj1[key] === obj2[key]);
  };
  React.useEffect(() => {
    if (!eventQuery.isLoading && eventQuery.data) {
      const resData = eventQuery.data;
      const address = `${resData?.physicalLocation?.address ?? ''}, ${resData?.physicalLocation?.city ?? ''}`.trim();
      form.setValue('country', resData.physicalLocation?.country?.toLowerCase() === 'nigeria' ? resData.physicalLocation?.country : '');
      form.setValue('state', resData.physicalLocation?.state?.toLowerCase() === 'lagos' ? resData.physicalLocation?.state : '');
      form.setValue('venueAddress', address);
      if (resData?.start) {
        form.setValue('eventDate', new Date(resData.start));
        if (resData?.end) {
          const {
            duration,
            startTime
          } = transformStartAndEnd(resData.start, resData.end);
          form.setValue('eventTime', startTime);
          form.setValue('duration', duration);
        }
      }
      setPreviousValues(form.getValues());
    }
  }, [eventQuery.isLoading, eventQuery.data]);
  return <ModalNew isOpen={params.get(MODAL_PARAMS.type) === MODAL_PARAMS.eventDetails} onClose={() => router.replace(pathname)} asDrawerOnMobile={true} title="Edit event details" description="" classNames={{
    content: 'w-full max-w-[440px]'
  }} footer={<div className="flex flex-col md:flex-row justify-between gap-4 items-center w-full">
          <Button type="button" className="w-full" onClick={() => {
      router.replace(pathname);
      if (eventQuery.isLoading) {
        queryClient.cancelQueries({
          queryKey: ['get-event-item-by-slug']
        });
      }
    }} variant="outline" disabled={updateEventMutation.isPending}>
            Cancel
          </Button>

          <Button type="button" className="w-full" disabled={areObjectsEqual(previousValues, form.watch()) || updateEventMutation.isPending || eventQuery.isLoading} onClick={() => form.handleSubmit(onSubmit)()}>
            {updateEventMutation.isPending ? <>
                <Loader2 className="animate-spin mr-2" size={18} /> Processing...
              </> : 'Save Changes'}
          </Button>
        </div>} data-sentry-element="ModalNew" data-sentry-component="EditEventDetailsModal" data-sentry-source-file="EditEventDetailsModal.tsx">
      {eventQuery.isLoading ? <EditEventDetailLoading /> : <form className="w-full flex flex-col gap-4 mb-10">
          <div className="space-y-4 w-full">
            <div className="w-full">
              <Controller name="venueAddress" control={form.control} render={({
            field,
            formState: {
              errors
            }
          }) => <AddressInput placeholder="Enter the address of the venue" label="Venue Address" {...field} errorMessage={errors?.venueAddress?.message} />} />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="w-full mb-4">
              <label className="text-sm font-medium" htmlFor="country">
                Country
              </label>
              <Controller name="country" control={form.control} render={({
            field,
            formState: {
              errors
            }
          }) => <SelectInput options={nigerianCountryCode.map(val => ({
            searchKey: val.country,
            label: val.country,
            value: val.country
          }))} errorMessage={errors.country?.message} placeholder="Select country" value={(field.value as string)} onChange={field.onChange} />} />
              {form.formState.errors?.country?.message && <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
                  {form.formState.errors?.country?.message}
                </p>}
            </div>
            <div className="w-full mb-4">
              <label className="text-sm font-medium" htmlFor="state">
                State
              </label>
              <Controller name="state" control={form.control} render={({
            field,
            formState
          }) => <SelectInput options={NigerianStates.map(state => ({
            searchKey: state,
            label: state,
            value: state
          }))} errorMessage={formState.errors.state?.message} placeholder="Select state" value={field.value?.toString()} onChange={field.onChange} />} />
              {form.formState.errors?.state?.message && <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
                  {form.formState.errors?.state?.message}
                </p>}
            </div>
          </div>

          <div className="w-full flex items-center justify-end gap-2 flex-row-reverse">
            <label htmlFor="multiple">Multiple days</label>
            <Controller name="multiple" control={form.control} render={({
          field
        }) => <Switch checked={field.value} onCheckedChange={field.onChange} aria-readonly />} />
          </div>

          <div className="w-full">
            <Controller name="eventDate" control={form.control} render={({
          field,
          fieldState: {
            error
          }
        }) => <DateField name={field.name} label="Date" value={field.value} onChange={field.onChange} error={error?.message ?? ''} minDate={new Date()} />} />
          </div>

          <div className="flex items-center gap-4 w-full">
            <div className="flex-grow">
              <label htmlFor="eventTime" className="text-base font-normal">
                Time
              </label>
              <Controller name="eventTime" control={form.control} render={({
            field,
            fieldState: {
              error
            }
          }) => <>
                    <Select onValueChange={field.onChange} value={field.value} defaultValue={field.value}>
                      <SelectTrigger className="mt-1.5" hasError={!!error?.message}>
                        <SelectValue placeholder="_ _:_ _  _ _" />
                      </SelectTrigger>
                      <SelectContent className="h-[200px]">
                        {generateTimeOptions().map(time => <SelectItem key={time} value={time}>
                            {time}
                          </SelectItem>)}
                      </SelectContent>
                    </Select>

                    {error?.message && <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
                        {error?.message}
                      </p>}
                  </>} />
            </div>
            <div className="flex-grow">
              <label htmlFor="duration" className="text-base font-normal flex items-center gap-0.5">
                Duration{' '}
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button size="icon" variant="ghost" className="h-fit w-fit p-0.5" type="button">
                      <Info size={14} className="text-yellow-brand" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>
                      Specify the <b>hour</b>:<b>minutes</b>
                    </p>
                  </TooltipContent>
                </Tooltip>
              </label>
              <Controller name="duration" control={form.control} render={({
            field,
            fieldState: {
              error
            }
          }) => <>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <SelectTrigger className="mt-1.5" hasError={!!error?.message}>
                        <SelectValue placeholder="hh:mm" />
                      </SelectTrigger>
                      <SelectContent className="h-[200px]">
                        {Array.from({
                  length: 24 * 4
                }, (_, i) => {
                  const hours = Math.floor(i / 4).toString().padStart(2, '0');
                  const minutes = ['00', '15', '30', '45'][i % 4];
                  return <SelectItem key={i} value={`${hours}:${minutes}`}>
                              {`${hours}:${minutes}`}
                            </SelectItem>;
                })}
                      </SelectContent>
                    </Select>
                    {error?.message && <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
                        {error?.message}
                      </p>}
                  </>} />
            </div>
          </div>
        </form>}
    </ModalNew>;
};
export default EditEventDetailsModal;