'use client';

import Image from 'next/image';
import React, { useState, useEffect } from 'react';
interface ValidatedImageProps {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  fill?: boolean;
  layout?: string;
  defaultUrl?: string;
  className?: string;
}
const Img: React.FC<ValidatedImageProps> = ({
  src,
  alt,
  width = 400,
  height = 400,
  fill,
  layout,
  defaultUrl,
  className
}) => {
  const [isValidImage, setIsValidImage] = useState(false);
  const defaultSrc = defaultUrl ? defaultUrl : '/assets/placeholder.svg';
  useEffect(() => {
    // Function to validate if the URL points to an image
    const validateImageUrl = async (url: string) => {
      try {
        const res = await fetch(url);
        const contentType = res.headers.get('content-type');
        if (res.ok && contentType && contentType.startsWith('image/')) {
          setIsValidImage(true);
        } else {
          setIsValidImage(false);
        }
      } catch (error) {
        setIsValidImage(false);
      }
    };
    validateImageUrl(src);
  }, [src]);
  if (fill) return <Image src={isValidImage ? src : defaultSrc} alt={alt} fill className={className} crossOrigin="anonymous"
  // priority
  />;
  if (layout === 'fill') return <Image src={isValidImage ? src : defaultSrc} alt={alt} layout={layout} className={className} crossOrigin="anonymous" priority />;
  return <Image src={isValidImage ? src : defaultSrc} alt={alt} height={height} width={width} className={className} crossOrigin="anonymous" priority data-sentry-element="Image" data-sentry-component="Img" data-sentry-source-file="img.tsx" />;
};
export default Img;