import { getCommunityBySlug } from '@/services/communities.service';
import { useQuery } from '@tanstack/react-query';

const useGetCommunityBySlug = ({ slug }: { slug: string | string[] }) => {
  return useQuery({
    queryKey: ['GetCommunityBySlug', { slug }],
    queryFn: () => getCommunityBySlug({ slug }),
    enabled: Boolean(slug),
  });
};

export default useGetCommunityBySlug;
