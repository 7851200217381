import { axiosClientInstance } from '@/configs/axiosClientInstance';
import { CLIENT_KEY, FUSION_API } from '@/configs/global-variables';
import { ChangeSettingsPassword } from '@/lib/models';
import { ValidatePasswordResetType } from '@/types';
import handleError, { ErrorPayload } from '@/utils/handleError';
import { AxiosError } from 'axios';

export const changeCurrentPassword = async (payload: ChangeSettingsPassword) => {
  try {
    const res = await axiosClientInstance.post('/Auth/ChangePassword', payload, {
      baseURL: FUSION_API.apiMicroserviceUserSecurity,
      params: { ...payload, clientKey: CLIENT_KEY },
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
};

export async function validatePasswordReset(payload: ValidatePasswordResetType) {
  try {
    const res = await axiosClientInstance.post('/Auth/PasswordResetComplete', payload, {
      baseURL: FUSION_API.apiMicroserviceUserSecurity,
    });
    return res.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}
